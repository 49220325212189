<template>
	<div class="vol-num">
		<span class='title'>志愿者年龄</span>
		<img src="../assets/9.png" alt="" class='img2' @click.stop="$emit('on-refresh')">
		<div class='vol-list'>
			<div class='left_inner'>
				<div class='point' ref="pointData"></div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import { getZyzAgeApi } from '../api/index'
    import echarts from 'echarts'
	export default {
		name: 'activitynum',
		data() {
			return {
				list:[],
				color: ["#009fe8","#00a199","#3090ae","#0ec0e5","#053c71"],
				seriesData: []
			}
		},
		methods: {
			async init(){
				await getZyzAgeApi({}).then((res) => {
					res = res.data
					if (res.code == 200) {
						this.list=res.data
						// console.log(this.list)
					} else {
						alert(res.msg)
					}
				}).catch((err) => {
					console.log(err)
				})
				let seriesData=[];
				for(let i=0;i<this.list.length;i++){
					seriesData.push({
			            value: this.list[i].num,
			            name: this.list[i].name,
			            label: {
			            	normal: {
					            textStyle: {
					                color: '#fff'
				                },
				                formatter: '{a|}{abg|}\n{hr|}\n{per|{d}%} \n{hr|}\n{b|'+this.list[i].name+'}\n{c}人',
				                borderWidth: 1,
				                borderRadius: 4,
				                rich: {
				                    a: {
				                        color: '#fff',
				                        lineHeight: 22,
				                        align: 'center'
				                    },
				                    hr: {
				                        width: '100%',
				                        borderWidth: 0.5,
				                        height: 0,
				                        color: '#fff',
				                    },
				                    b: {
				                        fontSize: 14,
				                        lineHeight: 16,
				                        color: '#fff',
				                    },
				                    per: {
				                        color: '#fff',
				                        fontSize: 14,
				                        padding: [2, 4],
				                        borderRadius: 2
				                    }
				                }
					        }
				        },
			            itemStyle: {
			                normal: {
			                    color: this.color[i]
			                }
			            }
					})
				}
				this.seriesData=seriesData;
				var option = {
				    backgroundColor: "transparent",
				    title: {
				        text: "",
				        left: "center",
				        top: 20,
				        textStyle: {
				            color: "#ccc"
				        }
				    },
				    tooltip: {
				        trigger: "item",
				        formatter: "{b} : {c} 人"
				    },
				    series: [{
				        name: "访问来源",
				        type: "pie",
				        radius: "70%",
				        center: ["50%", "50%"],
				        data: seriesData.sort(function(a, b) {
				            return a.value - b.value;
				        }),
				        roseType: "radius",
				        label: {
				            normal: {
				                textStyle: {
				                    color: "rgba(255, 255, 255, 1)"
				                }
				            }
				        },
				        labelLine: {
				            normal: {
				                lineStyle: {
				                    color: "rgba(255, 255, 255, 0.3)"
				                },
				                smooth: 0.2,
				                length: 10,
				                length2: 20
				            }
				        },
				        itemStyle: {
				            normal: {
				                // color: '#053c71',
				                // shadowBlur: 200,
				                // shadowColor: 'rgba(0, 0, 0, 0.5)'
				            }
				        },
				        animationType: "scale",
				        animationEasing: "elasticOut",
				        animationDelay: function(idx) {
				            return Math.random() * 200;
				        }
				    }]
				};

				this.myChart = echarts.init(this.$refs.pointData)
				this.myChart.setOption(option);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style scoped>
	.vol-num{
		color: #fff;
		width: 405px;
		height: 259px;
		/*background-color: rgba(23,42,136,0.2);*/
		margin-top: -3px;
		display: inline-block;
		/*margin-left: 20px*/
	}
	.title{
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		line-height:53px;
		margin-left: 20px;
	}

	.point{
		width: 350px;
		height: 220px;
	}
	
	.vol-list{
		margin-left: 20px;
		margin-top: -17px
	}
	.left_inner{
		width: 300px;
		height: 220px;
		position: relative;
		display: inline-block;
	}
	.img2{
		float: right;
		width: 20px;
		margin-top: 16px;
		margin-right: 16px;
		cursor: pointer;
	}
</style>