<template>
  <div class="box">
      <div>
        <img width="100%" src="@/assets/Group 599@2x.png" alt="" />
      </div>
    <div class="vol-num">

      <div class="mapimg">
        <ul>
          <li v-for="(item, i) in list" :key="i">
            <div class="listimg">
              <div class="imgsize">
                <img
                  width="79"
                  height="64"
                  :src="item.con_images!='' ? imglist +''+ item.con_images: require('@/assets/zanwei.png')"
                  alt=""
                />
              </div>
              <div class="listp">
                <h4 class="stylep" v-html="item.con_title"></h4>
                <div class="styleps" v-html="removeHtmlStyle(item.con_content)">
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getArticleRanklist, getBaseNumApi } from "../api/index";
export default {
  data() {
    return {
      imglist: "",
      list:[]
    };
  },
  methods: {
    myEcharts() {
      let form = new URLSearchParams();
      form.append("columnId", 89);
      getArticleRanklist(form).then((res) => {
         if(res.data.code == 200){
            this.list=res.data.data
            
         }
      });
    },
     removeHtmlStyle(html) {
      let rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
      let newHtml = "";
      if (html) {
        newHtml = html.replace(rel, "");
      }
      // 清除类名
      // let relClass = /class\s*?=\s*?([‘"])[\s\S]*?\1/g;
      // let newClassHtml = "";
      // if (newHtml) {
      //   newClassHtml = newHtml.replace(relClass, "");
      // }
      return newHtml;
    },
    getBaseNumApiapi() {
      getBaseNumApi().then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data[0].oss_url);
          this.imglist = res.data.data[0].oss_url;
          this.myEcharts();
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getBaseNumApiapi();
    });
  },
};
</script>

<style scoped>
.box {
  color: #fff;
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  background-color: #021347;
}
.vol-num {
  width: 100%;
  height:  316px;
  overflow-y: auto;
}
.title {
  width: 242px;
  height: 23px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 53px;
  margin-left: 20px;
}
.broken-line {
  width: 425px;
  height: 260px;
}
.left {
  display: flex;
  overflow: auto;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001336;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #001336;
}
::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  border-radius: 12px;
}
::-webkit-scrollbar {
  height: 4px !important; /*滚动条宽度*/
}
.mapimg {
  display: flex;
}
.titles {
  width: 35%;
  padding-top: 10px;
}
p {
  font-size: 7px;
}
.pnum {
  margin-top: 10px;
}
.spa {
  width: 12px;
  height: 22px;
  background: #005384;
  opacity: 1;
  margin-right: 2px;
  text-align: center;
  font-size: 12px;
  padding: 2px;
}
.times {
  margin-top: 20px;
}
.listp {
  display: flex;
  justify-content: flex-end;
  margin-left: 23px;
  flex-direction: column;
  height: 70px;
}
.listimg {
  display: flex;
  padding: 5px;
  align-items: center;
  margin-bottom:10px;
  height: 70px;
}
.stylep {
  font-size: 16px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  /* margin-bottom: 3px; */
}
.styleps {
  font-size: 14px !important;
  font-family: OPPOSans-Medium, OPPOSans !important;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
 
}
.imgsize {
  display: flex;
  align-items: center;
}
</style>