import 'normalize.css/normalize.css'
import './styles/index.css'
import Vue from 'vue'
import App from './App.vue'
import AnimatedInteger from './components/animated-integer.vue'
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.component('AnimatedInteger', AnimatedInteger)
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
new Vue({
  render: h => h(App),
  mounted() {

  }
}).$mount('#app')

;(function(doc, win) {
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function() {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      var ratio = clientWidth / 1920;
      document.body.style.cssText = `
          transform: scale(${ratio}); transform-origin: left top 0px; background-size: 100% 100%;
      `
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);


