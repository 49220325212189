<template>
  <div class="practice-rank">
    <div class="top_content">
      <img width="100%" src="@/assets/Group 533@2x.png" alt="" />
    </div>
    <div class="middle">
      <div class="inner_middle">
        <table border="0">
          <thead>
            <tr>
              <td width="150">点单标题</td>
              <td width="150">状态</td>
              <td width="150">时间</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>
                {{ item.title }}
              </td>
              <td>{{ getText(item.state) }}</td>
              <td>{{ getDate(item.createtime) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div class="bottom"></div> -->
  </div>
</template>

<script>
import { getServiceAreasApi, getMassListApi } from "../api";
export default {
  name: "ShowNumber",
  data() {
    return {
      title: "点单派单系统",
      list: [],
    };
  },
  methods: {
    init() {
      getMassListApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.list = res.data;
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDate(text) {
      let data = new Date(text * 1000);
      let year = data.getFullYear();
      let month =
        data.getMonth() + 1 < 10
          ? "0" + (data.getMonth() + 1)
          : data.getMonth() + 1;
      let date = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
      let hh = data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
      return year + "-" + month + "-" + date;
    },
    getText(item) {
      let obj = {
        0: "待处理",
        1: "待服务",
        2: "已完成",
        3: "已取消",
        4: "已拒绝",
      };
      // console.log(brokename)
      return obj[item];
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.practice-rank {
  height: 547px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #001d4f;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  background-color: #021347;
  /* opacity: 0.8; */
  border: 1px solid #13376c;
}

.top_content {
  width: 100%;
  /* height: 35px; */
  /* background-size: 100% 40px; */
  /* background-image: url(../assets/png_8.png); */
  position: relative;
}

.top_content .right_icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.top_content .right_icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.middle {
  height: 400px;
  background-size: 100%;
  background-image: url(../assets/png_9.png);
  flex: 1;
  overflow: hidden;
}

.middle .inner_middle {
  height: 100%;
  overflow-y: auto;
}
.middle .inner_middle::-webkit-scrollbar {
  width: 10px;
}
.middle .inner_middle::-webkit-scrollbar-track {
  background-color: #070707;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.middle .inner_middle::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.bottom {
  width: 100%;
  height: 40px;
  background-size: 100% 100%;
  background-image: url(../assets/png_10.png);
}

table {
  width: 100%;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background-color: #021347;
   
}

table thead td {
  height: 30px;
  font-size: 16px;
  font-weight: bold;
}

table img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: auto;
}

table tbody td {
  padding: 7.5px 0;
  font-size: 16px;
  
}

table .span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #20a3ff;
  line-height: 15px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  display: block;
  margin: auto;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001336;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #001336;
}
::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  border-radius: 12px;
}
::-webkit-scrollbar {
  height:4px !important; /*滚动条宽度*/
}
</style>
