<template>
	<div class="total-number-of-activities">
		<show-num title="活动数总量" :number="activity_num" :time="10*1000" klass="show-num" :tick="false" />
		<transition name="fade" mode="out-in">
			<components :is="curComp"/>
	    </transition>
	</div>
</template>

<script>
	import ShowNum from './show-num'
	import TotalNumberOfActivitiesList2 from './total-number-of-activities-list1'
	import TotalNumberOfActivitiesList1 from './total-number-of-activities-list2'

	import { 
		getActivityNumApi,
		getBaseNumApi
	} from '../api/index'

	export default {
		name: 'TotalNumberOfActivities',
		components: { ShowNum, TotalNumberOfActivitiesList2, TotalNumberOfActivitiesList1 },
		data() {
			return {
				uniKey: 100,
				curComp: 'TotalNumberOfActivitiesList1',
				activity_num: []
			}
		},
		methods: {
			numGoArray(n) {
		        var str = n + "";
		        var arr = [];
		        str.split("").forEach(function (item) {
		            arr.push(parseInt(item));
		        })
		        return arr;
			},
			key() {
        		return ++this.uniKey
			},
			async getActivityNum() {
				let res1 = await getActivityNumApi({})
				let res2 = await getBaseNumApi({})
				let activity_num=res1.data.data[0].count;
				activity_num=activity_num+res2.data.data[0].activity_num;
				this.activity_num=this.numGoArray(activity_num)
			},
		},
		mounted() {
			this.getActivityNum();
			this.timer = setInterval(() => {
				// this.getActivityNum();
				if (this.curComp == 'TotalNumberOfActivitiesList1') {
					this.curComp = 'TotalNumberOfActivitiesList2'
				} else if (this.curComp == 'TotalNumberOfActivitiesList2') {
					this.curComp = 'TotalNumberOfActivitiesList1'
				}
			}, 120000);
		},
		beforeDestroy() {
	    	clearInterval(this.timer);
	    }
	}
</script>

<style scoped>
	.total-number-of-activities {
	}

	.ul-box {
		list-style: none;
	}

	.ul-box>li {
		display: flex;
		margin: 15px 0;
	}

	.ul-box>li .title {
		font-size:12px;
		font-family:FZLanTingHei-R-GBK;
		font-weight:400;
		color:rgba(255,255,255,1);
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ul-box>li .title.first {
		width: 160px;
	}
	.ul-box>li .title.second {
		width: 60px;
	}
	.ul-box>li p{
		font-size:12px;
		font-family:FZLanTingHei-R-GBK;
		font-weight:400;
		color:rgba(252,238,33,1);
		display: flex;
		align-items: center;
	}
	.li-icon {
		display: inline-block;
		width: 14px;
		height: 14px;
		margin-right: 8px;
		background: url(../assets/6.png);
		background-size: contain;
		background-repeat: no-repeat;

	}
	.red {
		color: #E73828 !important;
	}

	.triangle-down {
		display: inline-block;
		margin-left: 10px;
		width: 0; 
		height: 0;
		border-width: 7px;
		border-style: solid;
		border-color:#E73828 transparent transparent transparent;
		margin-top: 8px;
	}

	.triangle-up {
		display: inline-block;
		margin-left: 10px;
		width: 0; 
		height: 0;
		border-width: 7px;
		border-style: solid;
		border-color: transparent transparent rgba(252,238,33,1) transparent;
		margin-top: -8px;
	}

	.fade-enter-active,
	.fade-leave-active {
	    transition: opacity 0.28s;
	}

	.fade-enter,
	.fade-leave-active {
	    opacity: 0;
	}
</style>

<style>
	.show-num {
		width: 38px !important;
		height: 70px !important;
		background: #05C2E6 !important;
		color: #fff !important;
		line-height: 70px !important;
		margin-right: 10px !important;
	}
</style>