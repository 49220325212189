import axios from 'axios'

axios.defaults.baseURL = 'http://bigdata.api.wmsjyun.com'
// axios.defaults.baseURL = 'http://10.10.0.237:8080'
axios.defaults.timeout = 60 * 1000

const apiToken = 'e1131cd2-6433-4542-9653-a72e11973bc5'

export function get(url, params, headers = {}) {
	headers = Object.assign({ 'token': apiToken },{'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"}, headers)
	return axios.get(url, { params, headers })
}

export function post(url, params, headers = {}) {
	headers = Object.assign({ 'token': apiToken },{'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"}, headers)
	return axios.post(url, params, { headers })
}