import { get, post } from '../common/axios'

// 基础总数
export function getBaseNumApi(data) {
    return post('/base/num/', data)
}
// 查询实践点年度总计划数量
export function getPosionNumApi(data) {
    return post("/posion/num", data);
}
// 活动总数
export function getActivityNumApi(data) {
    return post('/activity/num', data)
}

// 活动总时长
export function getActivityServicetimeNumApi(data) {
    return post('/activity/servicetime', data)
}


// 实践站总数
export function getRegionNumApi(data) {
    return post('/region/num', data)
}

// 查询实践站操作员列表
export function getRegionListApi(data) {
    return post('/region/list', data)
}

// 志愿者总数
export function getZyzNumApi(data) {
    return post('/zyz/num', data)
}

// 志愿组织总数
export function getOrgNumApi(data) {
    return post('/org/num', data)
}

// 志愿者注册趋势
export function getCreateTrendApi(data) {
    return post('/zyz/create-trend', data)
}

// 查询志愿者男女比例
export function getZyzSexApi(data) {
    return post('/zyz/sex', data)
}
export function getCountListApi(data) {
    return post("/zyz/get-count-list", data);
  }
// 志愿组织活动时长排名
export function getOrgActRankingApi(data) {
    return post('/org/act-ranking', data)
}

// 志愿者活动时长排名
export function getZyzRelRankingApi(data) {
    return post('/zyz/rel-ranking', data)
}

// 查询志愿者年龄段比例数量
export function getZyzAgeApi(data) {
    return post('/zyz/age', data)
}
// 数量统计
export function getStatisticsApi(data) {
    return post("/zyz/get-count-list", data);
  }
// 查询志愿者年龄段比例数量
export function getZyzEduApi(data) {
    return post('/zyz/edu', data)
}


// 活动类型
export function getActivityTypeApi(data) {
    return post('/activity/type', data)
}

// 点单列表
export function getMassListApi(data) {
    return post('/mass/list', data)
}

// 点单数据
export function getMassNumApi(data) {
    return post('/mass/num', data)
}

// 活动风采
export function getPlanOverApi(data) {
    return post("/activity/getList", data);
}
// 活动风采
export function getHaveActListApi(data) {
    return post("/activity/getList", data);
}
// 活动风采
export function getcountlist(data) {
    return post("/activity/area-activity-vol/num", data);
}
// export const getBrokelistApi = (params) => {
// 	let opt = {
//     	client_id: '5833eeba131f370d008b4567',
//     	type: 'all',
//     	page: 1,
//     	pageSize: 30
//     }
//     Object.assign(opt, params)

//     return axios.request({
//         url: '/webauth/js-brokelist.html',
//         params: opt,
//         method: 'get',
//     })
// }
// 实践站年度计划排行榜TOP
export function getPlanRankApi(data) {
    return post("/plan/plan-rank", data);
}
// 实践点活动计划完成排行榜TOP
export function getPlanNextRankApi(data) {
    return post("/plan/plan-next/rank", data);
}
// 中心动态
export function getArticleRanklist(data) {
    return post("/article/get-article-list", data);
}