import { render, staticRenderFns } from "./show-num.vue?vue&type=template&id=40902e6f&scoped=true"
import script from "./show-num.vue?vue&type=script&lang=js"
export * from "./show-num.vue?vue&type=script&lang=js"
import style0 from "./show-num.vue?vue&type=style&index=0&id=40902e6f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40902e6f",
  null
  
)

export default component.exports