import { render, staticRenderFns } from "./vol-zhuxingtu.vue?vue&type=template&id=4b501901&scoped=true"
import script from "./vol-zhuxingtu.vue?vue&type=script&lang=js"
export * from "./vol-zhuxingtu.vue?vue&type=script&lang=js"
import style0 from "./vol-zhuxingtu.vue?vue&type=style&index=0&id=4b501901&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b501901",
  null
  
)

export default component.exports