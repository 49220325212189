<template>
	<div class="vol-num">
		<p class='title'>进村入户面对面、文明实践手拉手</p>
		<div class='listInner1'>
			<span style="width:100px;display:inline-block">走访志愿者</span>
			<span style="font-size:16px;margin-left:20px;">66532 人</span>
		</div>
		<div class='listInner2'>
			<span style="width:100px;display:inline-block">覆盖群众</span>
			<span style="font-size:16px;margin-left:20px;">2174582 人</span>
		</div>
		<div class='listInner1'>
			<span style="width:100px;display:inline-block">收集问题</span>
			<span style="font-size:16px;margin-left:20px;">10986 个</span>
		</div>
		<div class='listInner2'>
			<span style="width:100px;display:inline-block">解决问题</span>
			<span style="font-size:16px;margin-left:20px;">10256 个</span>
		</div>
	</div>
</template>

<script>
    import echarts from 'echarts'
	export default {
		name: 'VolNum',
		data() {
			return {

			}
		},
		methods: {
		},
		mounted() {
		}
	};
</script>

<style scoped>
	.vol-num{
		color: #fff;
		width: 350px;
		height: 259px;
		/*background-color: rgba(23,42,136,0.2);*/
		margin-top:20px;
		display: inline-block;
		margin-left: 35px;
	}
	.title{
		/*width:242px;*/
		height:52px;
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		line-height:53px;
		margin-left: 10px;
	}
	
	.listInner1{
		font-size: 14px;
		color: #fff;
		/*width: 170px;*/
		height: 40px;
		text-align: left;
		padding-left: 15px;
		line-height: 40px;
		/*display: inline-block;*/
	}
	.listInner2{
		font-size: 14px;
		color: #fff;
		/*width: 170px;*/
		height: 40px;
		background-color: rgba(41,171,226,0.2);
		text-align: left;
		padding-left: 15px;
		line-height: 40px;
		/*display: inline-block;*/
	}
</style>