<template>
  <div style="position: relative">
    <div ref="map-polygon" class="map-polygon"></div>
    <!-- <div class="intro">
      <div class="item">
        <span class="red">
          <img src="../assets/png_21.png" /> </span
        >当日有活动
        {{ redCount }}
      </div>
      <div class="item">
        <span class="yellow"></span>本周有活动未开始 {{ yellowCount }}
      </div>
      <div class="item">
        <span class="green"></span>本周活动已结束 {{ greenCount }}
      </div>
      <div class="item"><span class="blue"></span>本周无活动</div>
    </div> -->
    <div class="modal_bg" v-if="ifShow" @click.stop="showModal(false)"></div>
    <div class="modal" v-if="ifShow">
      <div class="top">
        <div class="left">
          <div class="line"></div>
          {{ reginname }}
        </div>
        <div
          class="right"
          :class="
            modal_type == 'yellow'
              ? 'yellow'
              : modal_type == 'green'
              ? 'green'
              : modal_type == 'red'
              ? 'red'
              : 'blue'
          "
        >
          <span v-if="modal_type == 'yellow'" @click="showTable(regionid)"
            >本周有活动未开始</span
          >
          <span v-else-if="modal_type == 'green'">本周活动已结束</span>
          <span v-else-if="modal_type == 'red'" @click="showTable(regionid)"
            >当日有活动</span
          >
          <span v-else>本周无活动</span>
        </div>
      </div>
      <div class="middle">
        <div class="item" v-if="regionlevel != 3">
          <div class="stitle">年度计划总数</div>
          <div class="btitle">{{ info.posion_plan_num }}</div>
        </div>
        <div class="item">
          <div class="stitle">已完成活动数</div>
          <div class="btitle">{{ info.posion_plan_over_num }}</div>
        </div>
        <div class="item" v-if="regionlevel != 3">
          <div class="stitle">完成占比%</div>
          <div class="btitle">{{ info.percen }}</div>
        </div>
      </div>
      <div class="bottom" @click="screeningActDetail(true)">
        活动风采
        <img src="../assets/png_6.png" />
      </div>
      <div
        v-show="showActTable"
        class="act_table"
        :class="modal_type == 'yellow' ? 'yellowborder' : ''"
      >
        <img class="back" @click="goBack" src="../assets/act_back.png" alt="" />
        <div v-if="modal_type == 'yellow'" class="yellowTitle">
          本周有活动未开始
        </div>
        <div v-else-if="modal_type == 'red'" class="redTitle">当日有活动</div>
        <div class="actList">
          <table border="0" cellspacing="0">
            <thead :class="modal_type == 'yellow' ? 'yellowThead' : 'redThead'">
              <tr>
                <td width="100">活动时间</td>
                <td width="150">活动内容</td>
                <td width="180">活动阵地</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in actTableList" :key="index">
                <td>{{ item.starttime }}</td>
                <td>{{ item.title }}</td>
                <td>
                  {{ item.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRegionListApi,
  getPosionNumApi,
  getHaveActListApi,
} from "../api/index";
import echarts from "echarts";
import data from "./jiangning";
export default {
  name: "MapPolygon",
  data() {
    return {
      zoom: 1,
      list: [],
      areaList: [],
      streetList: [],
      communityList: [],
      PI: 3.14159265358979324,
      x_pi: (3.14159265358979324 * 3000.0) / 180.0,
      redCount: 0,
      yellowCount: 0,
      greenCount: 0,
      ifShow: false,
      info: "",
      showActTable: false,
      actTableList: [],
    };
  },
  methods: {
    screeningActDetail(params) {
      if (params) {
        this.$emit("showRight", false);
      } else {
        this.$emit("showRight", true);
      }
    },
    showModal(boolean, info) {
      if (this.showActTable) {
        return;
      }
      if (boolean) {
        // if (info.rankNameId) {
        //   this.modal_type = info.rankName;
        //   let form = new URLSearchParams();
        //   form.append("area5", info.rankNameId);
        //   getPosionNumApi(form)
        //     .then((res) => {
        //       res = res.data;
        //       if (res.code == 200) {
        //         this.ifShow = boolean;
        //         this.info = res.data;
        //       } else {
        //         alert(res.msg);
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        //   return;
        // }
        this.regionlevel = info.value[2];
        this.regionid = info.value[3];
        this.reginname = info.value[4];
        this.modal_type = info.value[5];
        this.area = info.value[6];
        let form = new URLSearchParams();
        if (this.regionlevel == 5) {
          form.append("area5", this.area);
        } else {
          // form.append("pid", this.regionid);
          form.append("area4", this.area);
        }
        getPosionNumApi(form)
          .then((res) => {
            res = res.data;
            console.log(res);
            if (res.code == 200) {
              this.info = res.data;
            } else {
              alert(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.regionid = ""; //重置查询最新活动风采
        this.screeningActDetail(false);
      }
      this.ifShow = boolean;
    },
    showTable(pid) {
      this.showActTable = true;
      let form = new URLSearchParams();
      if (this.regionlevel == 5) {
        form.append("area5", pid);
      } else {
        form.append("area4", pid);
      }
      getHaveActListApi(form)
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.actTableList = res.data;
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goBack() {
      this.showActTable = false;
    },
    //BD-09 to GCJ-02
    bd_decrypt(bdLat, bdLon) {
      var x = bdLon - 0.0065,
        y = bdLat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi);
      var gcjLon = z * Math.cos(theta);
      var gcjLat = z * Math.sin(theta);
      return { lat: gcjLat, lon: gcjLon };
    },
    delta(lat, lon) {
      // Krasovsky 1940
      //
      // a = 6378245.0, 1/f = 298.3
      // b = a * (1 - f)
      // ee = (a^2 - b^2) / a^2;
      var a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
      var ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
      var dLat = this.transformLat(lon - 105.0, lat - 35.0);
      var dLon = this.transformLon(lon - 105.0, lat - 35.0);
      var radLat = (lat / 180.0) * this.PI;
      var magic = Math.sin(radLat);
      magic = 1 - ee * magic * magic;
      var sqrtMagic = Math.sqrt(magic);
      dLat =
        (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * this.PI);
      dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * this.PI);
      return { lat: dLat, lon: dLon };
    },
    transformLat(x, y) {
      var ret =
        -100.0 +
        2.0 * x +
        3.0 * y +
        0.2 * y * y +
        0.1 * x * y +
        0.2 * Math.sqrt(Math.abs(x));
      ret +=
        ((20.0 * Math.sin(6.0 * x * this.PI) +
          20.0 * Math.sin(2.0 * x * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin((y / 3.0) * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((160.0 * Math.sin((y / 12.0) * this.PI) +
          320 * Math.sin((y * this.PI) / 30.0)) *
          2.0) /
        3.0;
      return ret;
    },
    transformLon(x, y) {
      var ret =
        300.0 +
        x +
        2.0 * y +
        0.1 * x * x +
        0.1 * x * y +
        0.1 * Math.sqrt(Math.abs(x));
      ret +=
        ((20.0 * Math.sin(6.0 * x * this.PI) +
          20.0 * Math.sin(2.0 * x * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin((x / 3.0) * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((150.0 * Math.sin((x / 12.0) * this.PI) +
          300.0 * Math.sin((x / 30.0) * this.PI)) *
          2.0) /
        3.0;
      return ret;
    },
    outOfChina(lat, lon) {
      if (lon < 72.004 || lon > 137.8347) return true;
      if (lat < 0.8293 || lat > 55.8271) return true;
      return false;
    },
    //WGS-84 to GCJ-02
    gcj_encrypt(wgsLat, wgsLon) {
      if (this.outOfChina(wgsLat, wgsLon)) return { lat: wgsLat, lon: wgsLon };

      var d = this.delta(wgsLat, wgsLon);
      return { lat: wgsLat + d.lat, lon: wgsLon + d.lon };
    },
    //GCJ-02 to WGS-84 exactly
    gcj_decrypt_exact(gcjLat, gcjLon) {
      var initDelta = 0.01;
      var threshold = 0.000000001;
      var dLat = initDelta,
        dLon = initDelta;
      var mLat = gcjLat - dLat,
        mLon = gcjLon - dLon;
      var pLat = gcjLat + dLat,
        pLon = gcjLon + dLon;
      var wgsLat,
        wgsLon,
        i = 0;
      while (1) {
        wgsLat = (mLat + pLat) / 2;
        wgsLon = (mLon + pLon) / 2;
        var tmp = this.gcj_encrypt(wgsLat, wgsLon);
        dLat = tmp.lat - gcjLat;
        dLon = tmp.lon - gcjLon;
        if (Math.abs(dLat) < threshold && Math.abs(dLon) < threshold) break;

        if (dLat > 0) pLat = wgsLat;
        else mLat = wgsLat;
        if (dLon > 0) pLon = wgsLon;
        else mLon = wgsLon;

        if (++i > 10000) break;
      }
      //console.log(i);
      return { lat: wgsLat, lon: wgsLon };
    },
    async init() {
      await getRegionListApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            let areaList = [],
              streetList = [],
              communityList = [],
              list = [];
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].level) {
                list.push(res.data[i]);
              }
            }
            this.list = list;
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      echarts.registerMap("guangzhou", data, {});
      var mapData = [
        // 地图数据
      ];
      let that = this;
      var convertData = function (data) {
        var res = [];
        let redCount = 0;
        let yellowCount = 0;
        let greenCount = 0;
        for (var i = 0; i < data.length; i++) {
          let lal = [];
          let text = data[i].region_name;
          let transformation1 = that.bd_decrypt(
            data[i].longitude.split("°")[0],
            data[i].latitude.split("°")[0]
          );
          let transformation2 = that.gcj_decrypt_exact(
            transformation1.lat,
            transformation1.lon
          );
          let areaText = "area" + data[i].level;
          let color = "";
          if (data[i].red > 0) {
            color = "red";
            redCount++;
          } else if (data[i].yellow > 0) {
            color = "yellow";
            yellowCount++;
          } else if (data[i].green > 0) {
            color = "green";
            greenCount++;
          } else {
            color = "blue";
          }
          that.redCount = redCount;
          that.yellowCount = yellowCount;
          that.greenCount = greenCount;
          // if (color == "red") {
          //   console.log(data[i].name);
          // }

          if (data[i].level == 4) {
            lal.push(
              data[i].longitude.split("°")[0],
              data[i].latitude.split("°")[0],
              data[i].level,
              data[i].id,
              data[i].region_name,
              color,
              data[i].region_id
            );
          } else {
            lal.push(
              data[i].longitude.split("°")[0],
              data[i].latitude.split("°")[0],
              data[i].level,
              data[i].id,
              data[i].region_name,
              color,
              data[i].region_id
            );
          }

          res.push({
            name: text,
            value: lal,
          });
        }
        // console.log(res)
        return res;
      };

      let option = {
        // echarts 配置
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        geo: {
          // 地图配置
          show: true,
          map: "guangzhou",
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: "#002255",
              // areaColor: "rgba(0, 34, 85, 0.4)",
              borderColor: "rgba(0, 184, 255, 0.6)",
            },
            emphasis: {
              areaColor: "#002255",
            },
          },
          zoom: 1.25,
        },
        tooltip: {
          formatter: function (params) {
            return params.name;
          },
        },
        series: [
          {
            // 散点配置
            name: "街道",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: convertData(this.list),
            symbolSize: function (val) {
              let room = that.zoom;
              if (val[5] == "red") {
                return 0;
              } else if (val[5] == "yellow" && val[2] == 4) {
                return 20 * room;
              } else if (val[5] == "yellow" && val[2] == 5) {
                return 15 * room;
              } else if (val[5] == "green" && val[2] == 4) {
                return 20 * room;
              } else if (val[5] == "green" && val[2] == 5) {
                return 15 * room;
              } else {
                return val[2] == 4
                  ? 0
                  : val[2] == 5
                  ? 12 * room
                  : val[2] == 3
                  ? 8 * room
                  : 5 * room;
              }
            },
            showEffectOn: "render",
            // rippleEffect: {
            //   scale: 0,
            //   brushType: "fill",
            // },
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                // color: "#EFDE0B",
                color: function (e) {
                  const { data, value } = e;
                  let v = value[5];
                  if (v == "red") {
                    return "#FF0606";
                  } else if (v == "yellow") {
                    return "#FF9000";
                  } else if (v == "green") {
                    return "#00FF30";
                  } else {
                    return "#00CCFF";
                  }
                },
              },
              label: {
                show: false,
                formatter: function (params) {
                  return params.name + "\r\n" + params.value;
                },
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
          {
            // 散点配置
            name: "街道",
            type: "effectScatter",
            selectedMode: "single",
            coordinateSystem: "geo",
            data: convertData(this.list),
            symbol:
              "image://http://wmsjyun.oss-cn-hangzhou.aliyuncs.com/1/2020-08-06/65894407475ec52f056e79a7aeaf4a2e.png",
            symbolSize: function (val) {
              let room = that.zoom;
              if (val[2] == 3 && val[5] == "blue") {
                return 30 * room;
              } else if (val[2] == 5 && val[5] == "red") {
                return 25 * room;
              } else if (val[5] == "red") {
                return 20 * room;
              } else {
                return 0;
              }
            },
            showEffectOn: "emphasis",
            rippleEffect: {
              scale: 0,
              brushType: "fill",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              label: {
                show: false,
                formatter: function (params) {
                  return params.name + "\r\n" + params.value;
                },
              },
            },
          },
          {
            // 散点配置
            name: "街道",
            type: "effectScatter",
            selectedMode: "single",
            coordinateSystem: "geo",
            data: convertData(this.list),
            symbol:
              "image://http://wmsjyun.oss-cn-hangzhou.aliyuncs.com/1/2020-08-06/68d7c99248ae43367eaf83929b219de7.png",
            symbolSize: function (val) {
              let room = that.zoom;
              return val[2] == 4 ? 25 * room : 0; //实践所leave 4
            },
            showEffectOn: "emphasis",
            rippleEffect: {
              scale: 0,
              brushType: "fill",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              label: {
                show: false,
                formatter: function (params) {
                  return params.name + "\r\n" + params.value;
                },
              },
            },
          },
          {
            // 地图配置
            name: "工程数",
            type: "map",
            mapType: "jiangning", // 自定义扩展图表类型
            geoIndex: 0,
            // aspectScale: 0.75, // 长宽比
            itemStyle: {
              normal: { label: { show: true } },
              emphasis: { label: { show: true } },
            },
            data: mapData,
          },
        ],
      };

      this.myChart = echarts.init(this.$refs["map-polygon"]);
      this.myChart.setOption(option);
      let self = this;
      this.myChart.on("click", function (params) {
        if (params.seriesName == "街道") {
          console.log(params);
          self.showModal(true, params);
        }
      });
    },
  },
  mounted() {
    this.zoom = 1;
    this.init();
  },
};
</script>

<style scoped>
.map-polygon {
  color: #fff;
  width: 100%;
  height: 645px;
  margin-top: 10px;
}
.intro {
  width: 150px;
  font-size: 13px;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 50px;
}

.intro .item {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.intro .item span {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
}

.intro .item span.yellow {
  background-color: #ff9000;
}

.intro .item span.green {
  background-color: #00ff30;
}

.intro .item span.red {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-left: -3px;
  margin-right: 13px;
}

.intro .item img {
  width: 100%;
  height: 100%;
  display: block;
}

.intro .item span.blue {
  background-color: #00ccff;
}
.modal_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}
.modal {
  width: 500px;
  height: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../assets/png_15.png);
  background-size: 100% 100%;
  padding: 40px 50px;
  z-index: 9999999;
}

.modal .top {
  width: 100%;
  display: flex;
  align-items: center;
  color: #00b8ff;
  font-size: 16px;
  margin-bottom: 30px;
}

.modal .top .left {
  flex: 1;
}

.modal .top .left .line {
  width: 4px;
  height: 15px;
  background-color: #00b8ff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.modal .top .right {
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #00ccff;
  border: 1px solid #00ccff;
  font-size: 13px;
  padding: 0 10px;
  cursor: pointer;
}

.modal .top .right.yellow {
  color: #ff9000;
  border: 1px solid #ff9000;
}

.modal .top .right.green {
  color: #00ff30;
  border: 1px solid #00ff30;
}

.modal .top .right.red {
  color: #ff0606;
  border: 1px solid #ff0606;
}

.modal .top .right.blue {
  color: #00ccff;
  border: 1px solid #00ccff;
}

.modal .middle {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.modal .middle .item {
  width: 61px;
  flex: 1;
  text-align: center;
}

.modal .middle .item .stitle {
  font-size: 12px;
}

.modal .middle .item .btitle {
  font-size: 32px;
  margin-top: 13px;
}

.modal .bottom {
  width: 186px;
  height: 40px;
  line-height: 40px;
  margin: 40px auto 0;
  border: 1px solid #00b8ff;
  color: #00b8ff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 58px;
  cursor: pointer;
  border-radius: 5px;
}

.modal .bottom img {
  width: 58px;
  height: 14px;
  display: block;
}
.act_table {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 260px;
  width: 85%;
  z-index: 999;
  border: 1px solid #da261e;
  background-color: rgba(0, 19, 48, 0.95);
  padding: 10px;
  overflow: hidden;
}
.yellowborder {
  border: 1px solid #ff9000 !important;
}
.back {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  z-index: 990;
}
.yellowTitle {
  height: 40px;
  text-align: center;
  position: relative;
  font-weight: bold;
  font-size: 16px;
  color: #ff9000;
  font-family: Microsoft YaHei;
}
.redTitle {
  height: 40px;
  text-align: center;
  position: relative;
  font-weight: bold;
  font-size: 16px;
  color: #da261e;
  font-family: Microsoft YaHei;
}
.actList {
  width: 100%;
  height: 85%;
  color: #fff;
  overflow-y: auto;
  padding-right: 20px;
}
table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.redThead {
  background-color: #da261e;
}
.redThead tr {
  background-color: #da261e;
}
.yellowThead {
  background-color: #ff9000;
}
.yellowThead tr {
  background-color: #ff9000;
}
table thead td {
  height: 40px;
  font-weight: bold;
}
table tbody td {
  height: 30px;
  padding: 10px 0;
}
table tbody tr:nth-child(2n) {
  background-color: rgba(255, 255, 255, 0.05);
}
</style>
