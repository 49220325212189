<template>
  <div class="vol-num">
    <div>
      <img width="100%" src="@/assets/Group 536@2x.png" alt="" />
    </div>
    <div class="left">
      <dv-scroll-board :config="configs" style="width: 500px; height: 220px" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      configs: {
        header: ["序号", "任务名称", "涉及点位", "计划数", "完成数", "完成度"],
        data: [
          [
            '<span style="background: #37a2da;width:10px;height:10px;border-radius: 50%;display: inline-block; margin-right:10px"></span>行1列1',
            "行1列2",
            "行1列3","行1列3","行1列3",
          ],
          [
            '<span style="background: #37a2da;width:10px;height:10px;border-radius: 50%;display: inline-block; margin-right:10px"></span>行1列1',
            '<span style="color:#32c5e9;">行2列2</span>',
            "行2列3","行1列3","行1列3",
          ],
        ],
        headerBGC: "#284594",
        waitTime: 10000000,
        oddRowBGC: "#284594", //奇数行背景色
        evenRowBGC: "#001D4F", //偶数行背景色
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
.vol-num {
  color: #fff;
  width: 100%;
  height: 381px;
  background-color: rgba(23, 42, 136, 0.2);
  margin-top: 20px;
  display: inline-block;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
}
.title {
  width: 242px;
  height: 23px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 53px;
  margin-left: 20px;
}
.broken-line {
  width: 425px;
  height: 260px;
}
.left {
  display: flex;
  overflow: auto;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001336;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #001336;
}
::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  border-radius: 12px;
}
::-webkit-scrollbar  
{  
    height: 4px !important;  /*滚动条宽度*/
}  
</style>