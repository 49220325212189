<template>
	<div class="order-today">
		<p class="title">本月点单</p>
		<div class='vol-list'>
			<ul class='ul_list nwwest-roll'>
				<li style="z-index:2">
					<div>
						<div class='inner_list1'>序号</div>
						<div class='inner_list2'>需求内容</div>
						<div class='inner_list3'>地点</div>
						<div class='inner_list4'>时间</div>
						<div class='inner_list5'>状态</div>
					</div>
				</li>
				<li v-for="(item,index) in list" :key="index" ref="rollul" :class="{anim:animate==true}">
					<div>
						<div class='inner_list1'>
							<span class="spot">
								{{ index+1 }}
							</span>
						</div>
						<div class='inner_list2'>{{ item.title }}</div>
						<div class='inner_list3'>{{ item.area }}</div> 
						<div class='inner_list4'>{{ getDate(item.createtime) }}</div>
						<div class='inner_list5'>{{ getText(item.state) }}</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
    import { getMassListApi } from '../api/index'

	export default {
		name: 'OrderToday',
		data() {
			return {
				animate:true,
				list: []
			}
		},
		methods: {
			getDate(text){
				let data=new Date(text*1000)
				let year = data.getFullYear();
			    let month =data.getMonth() + 1 < 10? "0" + (data.getMonth() + 1): data.getMonth() + 1;
			    let date =data.getDate() < 10? "0" + data.getDate(): data.getDate();
			    let hh =data.getHours() < 10? "0" + data.getHours(): data.getHours();
			    return year + "-" + month + "-" + date
			},
			getBrokelist() {
				getMassListApi().then((res) => {
					res = res.data
					if (res.code == 200) {
						this.list = res.data.slice(0,5)
					} else {
						alert(res.msg)
					}
                    
				}).catch((err) => {
					// console.error(456, err)
				})
			},
			getText(item) {
				let obj = {
					'0': '待处理',
					'1': '待服务',
					'2': '已完成',
					'3': '已取消',
					'4': '已拒绝'
				}
				// console.log(brokename)
				return obj[item]
			},
			getClass(item) {
				let state1 = 'state1' //蓝色
				let state2 = 'state2' //灰色
				let state3 = 'state3' //黄色
				if (item.brokename.trim() == '加急') {
					return 'red'
				}
				if (item == -1 || item.ischeck == 0) {
					return 'state2'
				}
				if (item.ischeck == 1) {
					return 'state3'
				}
				if (item.ischeck == 2 || item.ischeck == 3 || item.ischeck == 4) {
					return 'state1'
				}

			},
			scroll(){
			        let con1 = this.$refs.rollul;
			//         con1[0].style.marginTop='30px';
			        this.animate=!this.animate;
			        var that = this; 
			        setTimeout(function(){
			            that.list.push(that.list[0]);
			            that.list.shift();
			//             con1[0].style.marginTop='0px';
			            that.animate=!that.animate;
			        },0)
			  }
		},
		mounted(){
		    // setInterval(this.scroll,3000) 
		    
		    this.getBrokelist()
		    // this.timer = setInterval(this.getBrokelist, 120000);
		},
		beforeDestroy() {
	    	// clearInterval(this.timer);
	    }
	};
</script>

<style scoped>
	.title {
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		padding: 15px 0 10px 20px;
	}

	.vol-list {
		padding: 0 30px;
	}
	.ul_list {
		list-style: none;
		overflow:hidden;
		transition:all 0.5s;
		height: 210px
	}

	.inner_list1{
		width: 40px;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 14px;
		color: #fff;
		text-align: center;
	}
	.inner_list2{
		width: 160px;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 14px;
		color: #fff;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.inner_list3{
		width: 80px;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 14px;
		color: #fff;
		text-align: center;
		/*overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;*/
	}
	.inner_list4{
		width: 100px;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		font-size: 14px;
		color: #fff;
		text-align: center;
	}
	.inner_list5{
		width: 120px;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		font-size: 14px;
		color: #fff;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.img2{
		float: right;
		width: 20px;
		margin-top: 16px;
		margin-right: 16px;
		cursor: pointer;
	}
	.ul_list>li>div {
		padding: 5px 0;
	}
	.ul_list>li:nth-child(odd) {
		background-color: rgba(75,162,249,0.1);
	}

	.spot {
		display: inline-block;
		width: 18px;
		height: 18px;
		text-align: center;
		line-height: 20px;
		background: #009fe8;
		color: #fff;
		border-radius: 50%;
	}

	.state1 {
        color: #4BA2F9;
	}
	.state2 {
		color: #727171;
	}
	.state3 {
		color: #FFF100;
	}
	.red {
		color: red;
	}
	.anim{
		transition: all 0.5s
	}
</style>