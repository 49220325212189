<template>
	<ul class="ul-box">
		<li v-for="(item,index) in list" :key="index" v-if="(active-1)*5<=index&&index<active*5">
			<i class="li-icon"></i>
			<span class="title first">{{item.value1}}</span>
			<span class="title second">{{item.num}}</span>
			<p class="red">
				<span>本月占比 21%  环比  1%</span>
				<i class="triangle-up"></i>
			</p>
		</li>
	</ul>
</template>

<script>
	import { getActivityTypeApi } from '../api/index'

	export default {
		name: 'TotalNumberOfActivitiesList2',
		data(){
			return{
				list: [],
				active: 1
			}
		},
		methods:{
			dateFormat(time) {
				var date=new Date(time);
				var year=date.getFullYear();
				/* 在日期格式中，月份是从0开始的，因此要加0
				* 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
				* */
				var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
				var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
				var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
				var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
				var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
				// 拼接
				return year+"-"+month+"-"+day
			},
			getList() {
				var now = new Date(); //当前日期 
				var nowMonth = now.getMonth(); //当前月 
				var nowYear = now.getFullYear(); //当前年 
				//本月的开始时间
				var monthStartDate = new Date(nowYear, nowMonth, 1); 
				//本月的结束时间
				var monthEndDate = new Date(nowYear, nowMonth+1, 0);
				let form= new URLSearchParams()
				form.append('begintime',  this.dateFormat(monthStartDate))
				form.append('endtime',  this.dateFormat(monthEndDate))
				getActivityTypeApi(form).then((res) => {	
					let data=res.data;
					if (data.code == 200) {
						this.list=data.data
					} else {
						alert(data.msg)
					}
				}).catch((err) => {
					console.log(err)
				})
			},
			changeList(){
				this.active++;
				if(this.active*5>this.list.length){
					this.active=1;
				}
			}
		},
		mounted() {
			this.getList()
			// this.timer1 = setInterval(this.getList, 120000);
			// this.timer2 = setInterval(this.changeList, 2000);
		},
		beforeDestroy() {
	    	// clearInterval(this.timer1);
	    	// clearInterval(this.timer2);
	    }
	}
</script>

<style scoped>
	.total-number-of-activities {
	}

	.ul-box {
		list-style: none;
		margin-top: 10px;
	}

	.ul-box>li {
		display: flex;
		margin-bottom: 10px;
		align-items: center;
	}

	.ul-box>li .title {
		font-size:12px;
		font-family:FZLanTingHei-R-GBK;
		font-weight:400;
		color:rgba(255,255,255,1);
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ul-box>li .title.first {
		width: 100px;
		line-height: 100%;
	}
	.ul-box>li .title.second {
		width: 60px;
	}
	.ul-box>li p{
		font-size:12px;
		font-family:FZLanTingHei-R-GBK;
		font-weight:400;
		color:rgba(252,238,33,1);
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 190px;
		/*border: 1px solid green;*/
	}
	.ul-box>li p i {
		display: inline-block;
	}
	.li-icon {
		display: inline-block;
		width: 14px;
		height: 14px;
		margin-right: 8px;
		background: url(../assets/6.png);
		background-size: contain;
		background-repeat: no-repeat;

	}
	.red {
		color: #E73828 !important;
	}
	.yellow {
		color: rgba(252,238,33,1) !important;
	}

	.triangle-down {
		display: inline-block;
		margin-left: 10px;
		width: 0; 
		height: 0;
		border-width: 7px;
		border-style: solid;
		border-color:rgba(252,238,33,1) transparent transparent transparent;
		margin-top: 8px;
	}

	.triangle-up {
		display: inline-block;
		margin-left: 10px;
		width: 0; 
		height: 0;
		border-width: 7px;
		border-style: solid;
		border-color: transparent transparent  #E73828 transparent;
		margin-top: -8px;
	}

	.fade-enter-active,
	.fade-leave-active {
	    transition: opacity 0.28s;
	}

	.fade-enter,
	.fade-leave-active {
	    opacity: 0;
	}
</style>

<style>
	.show-num {
		width: 38px !important;
		height: 70px !important;
		background: #05C2E6 !important;
		color: #fff !important;
		line-height: 70px !important;
		margin-right: 10px !important;
	}
</style>