<template>
  <div class="practice-rank">
    <div class="top_content">
      <img width="100%" src="@/assets/Group 600@2x.png" alt="" />
    </div>
    <div class="middle">
      <div class="inner_middle">
        <div class="broken-line" ref="volNum"></div>
      </div>
    </div>
    <!-- <div class="bottom"></div> -->
  </div>
</template>

<script>
import { getCreateTrendPointApi, getCountListApi } from "../api/index";
let getRandomInt = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

let shuffle = function (arr) {
  let _arr = arr.slice();
  for (let i = 0; i < _arr.length; i++) {
    let j = getRandomInt(0, i);
    let t = _arr[i];
    _arr[i] = _arr[j];
    _arr[j] = t;
  }
  return _arr;
};

function getRadomNum() {
  let arr = [55, 54, 60, 68, 99, 100];
  return arr[Math.floor(Math.random() * arr.length)];
}

import echarts from "echarts";
export default {
  name: "VolNum",
  data() {
    return {
      list: [],
      title: "活跃志愿者",
    };
  },
  methods: {
    wrappData(baseArr, total) {
      var setArr = [];
      baseArr.forEach((item, index) => {
        let params = {
          data: item.count,
          value: ((item.count / total) * 100).toFixed(1),
        };
        setArr.push(params);
      });

      // var currNum = 0;
      // baseArr.forEach((item, index) => {
      //   if (index == 0) {
      //     let params = {
      //       data: baseArr[index],
      //       value: (baseArr[index] / total).toFixed(4),
      //     };
      //     setArr.push(params);
      //   } else {
      //     if (currNum) {
      //       currNum = baseArr[index] + currNum;
      //     } else {
      //       currNum = baseArr[index] + baseArr[index - 1];
      //     }
      //     let params = {
      //       data: currNum,
      //       value: (currNum / total).toFixed(4),
      //     };
      //     setArr.push(params);
      //   }
      // });
      return setArr;
    },
    async init() {
      let form = new URLSearchParams();
      form.append("year", new Date().getFullYear());
      form.append("type", 3);
      let res = await getCountListApi(form);
      if (res.data.code == 200) {
        console.log(res.data.data.data);
        let total = parseInt(res.data.data.data[0].total);
        this.list = this.wrappData(res.data.data.data, total);
      } else {
        alert(res.data.msg);
      }

      var visitIconOption = {
        tooltip: {
          trigger: "axis",
          confine: true,
          triggerOn: "mousemove",
          show: true,
          axisPointer: {
            type: "line",
          },
          // formatter: "{b}月份：{c}%",
          formatter(params) {
            for (let x in params) {
              return (
                params[x].name +
                "月份：" +
                params[x].data.value +
                "%" +
                "<br/>" +
                "总数：" +
                params[x].data.data
              );
            }
          },
        },
        grid: {
          top: "20",
          left: "5",
          bottom: "10",
          right: "0",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          boundaryGap: false,
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          axisLine: {
            lineStyle: {
              color: "#0A4E8C",
            },
          },
          axisTick: {
            length: 4,
          },
          axisLabel: {
            textStyle: {
              color: "white",
            },
            margin: 10,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#0A4E8C",
            },
          },
          axisTick: {
            length: 4,
          },
          axisLabel: {
            formatter: "{value}%",
            textStyle: {
              color: "white",
            },
            margin: 10,
          },
        },
        series: [
          {
            name: "活跃志愿者",
            type: "line",
            data: this.list,
            color: ["#069be2"],
            smooth: false,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(22,150,217,.8)",
                  },
                  {
                    offset: 1,
                    color: "rgba(3,25,65,.8)",
                  },
                ]),
              },
            },
          },
        ],
      };

      if (this.myChart) {
        this.myChart.clear();
        this.myChart.setOption(visitIconOption);
      } else {
        this.myChart = echarts.init(this.$refs.volNum);
        this.myChart.setOption(visitIconOption);
      }
    },
  },
  mounted() {
    this.init();
    // this.timer = setInterval(this.init, 120000);
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
};
</script>

<style scoped>
.practice-rank {
  width: 38%;
  height: 332px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #021347;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  opacity: 0.8;
  border: 1px solid #13376c;
}

.top_content {
  width: 100%;
  /* height: 35px; */
  background-size: 100% 40px;
  /* background-image: url(../assets/png_8.png); */
  position: relative;
}

.top_content .right_icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.top_content .right_icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.middle {
  /* width: 483px; */
  height: 400px;
  background-size: 100%;
  /* background-image: url(../assets/png_9.png); */
  flex: 1;
  overflow: hidden;
}

.middle .inner_middle {
  width: 100%;
  height: 100%;
  padding-right: 20px;
}

.bottom {
  width: 100%;
  height: 40px;
  background-size: 100% 100%;
  background-image: url(../assets/png_10.png);
}

.broken-line {
  width: 100%;
  height: 100%;
}

.refresh {
  float: right;
  width: 15px;
  height: 16px;
  margin-top: -25px;
  margin-right: 20px;
  cursor: pointer;
}
</style>
