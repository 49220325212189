<template>
  <div class="scroll-content">
    <vue-seamless-scroll
      :data="list"
      :class-option="classOption"
      class="content"
    >
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="showModal(true,index)"
      >
        <div class="pic">
          <img :src="completionUrl(item.activity_images)" />
        </div>
        <div class="title">{{ item.activity_name }}</div>
      </div>
    </vue-seamless-scroll>
    <div class="modal_bg" v-if="ifShow" @click="showModal(false)">
      <el-carousel
        :interval="4000"
        :initial-index='num'
        trigger="click"
        arrow="always"
        :autoplay="false"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <img :src="completionUrl(item.activity_images)" class="modal" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getPlanOverApi, getBaseNumApi } from "../api";

export default {
  name: "ShowNumber",
  components: {
    vueSeamlessScroll,
  },
  props: {},
  computed: {
    classOption() {
      return {
        step: 1.5,
        limitMoveNum: 5,
        openTouch: false,
        waitTime: 1,
        direction: 2,
        singleWidth: 30,
      };
    },
  },
  data() {
    return {
      imgList: [],
      ifShow: false,
      list: [],
      imageUrl: "",
      num:null
    };
  },
  methods: {
    async getBase() {
      let res1 = await getBaseNumApi({});
      this.imageUrl = res1.data.data[0].oss_url;
      this.getList();
    },
    completionUrl(address) {
      if (address) {
        return this.imageUrl + address.split(",")[0];
      } else {
        return "";
      }
    },
    wrapperData(obj) {
      return Object.keys(obj).map((key) => {
        console.log(obj[key]["images"]);
        let array = obj[key]["images"] ? obj[key]["images"].split(",") : "";
        let result = [];
        for (let i = 0; i < array.length; i++) {
          if (array[i]) {
            result.push(array[i]);
          }
        }
        obj[key]["images"] = result;
        return obj[key];
      });
    },
    getList() {
      getPlanOverApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.list = res.data
             this.imgList = res.data
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showModal(boolean, val) {
      this.ifShow = boolean;
      this.num=val
    },
  },
  mounted() {
    this.getBase();
    this.timer = setInterval(this.getBase, 30 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.scroll-content {
  width: 100%;
  height: 215px;
  background-size: 100% 100%;
  /*position: relative;*/
    margin-top:10px;
  z-index: 10;
  background-color: #021347;
  overflow: hidden;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  border: 1px solid #13376C;
}

.content {
  width: 1920px;
  margin: 20px auto;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-top: 20px;
}

.item {
  width: 180px;
  height: 130px;
  margin: 0 11px;
  flex-shrink: 0;
}

.item .pic {
  width: 100%;
  height: 100px;
  border: 4px solid #037dd0;
  box-sizing: border-box;
}

.item .pic img {
  width: 100%;
  height: 100%;
  display: block;
}

.item .title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background-color: #037dd0;
  margin-top: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 10px;
}

.modal_bg {
  width: 1920px;
  height: 1080px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);
}

.el-carousel__item {
  width: 100%;
  height: 100%;
}

.el-carousel__item img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<style>
.scroll-content .content > div,
.scroll-content .content > div > div {
  float: none !important;
  display: flex;
  overflow: visible !important;
}

.scroll-content .el-carousel__container {
  width: 960px;
  height: 600px;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 450px;
  left: 1000px;
  transform: translate(-50%, -50%);
  z-index: 25;
}

.scroll-content .el-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  height: 60px;
  width: 60px;
}
</style>
