<template>
	<div class="vol-num">
		<span class='title'>志愿者性别</span>
		<img src="../assets/9.png" alt="" class='img2' @click.stop="$emit('on-refresh')">
		<div class='vol-list'>
			<div>
				<ul class='ul_inner1'>
					<li v-for="(i, index) in renderData.man" :key="index">
						<img src="../assets/16.png" alt="" class='img1'>
					</li>
				</ul>
				<div class='inline_block'>
					<p style='font-size:16px;'>{{ renderData.manRatio }}</p>
					<p style='font-size:10px;'>{{ renderData.manPeople }}</p>
				</div>
			</div>
			<div>
				<ul class='ul_inner1'>
					<li v-for="(i, index) in renderData.woman" :key="index">
						<img src="../assets/15.png" alt="" class='img1'>
					</li>
				</ul>
				<div class='inline_block'>
					<p style='font-size:16px;'>{{ renderData.womanRatio }}</p>
					<p style='font-size:10px;'>{{ renderData.womanPeople }}</p>
				</div>
			</div>
		</div>
		<div>
			<div class='inline_block'>
				<span class='square_mal'></span>
				<span style="font-size:12px;">男</span>
			</div>
			<div class='inline_block'>
				<span class='square_fel'></span>
				<span style="font-size:12px;">女</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { getZyzSexApi } from '../api/index'
	var getRandomItem = function () {
	    var preItem = null

	    return function (arr) {

	        var index = Math.floor(Math.random() * arr.length),
	            item = arr[index],
	            result

	        arr = arr.sort(function() { 
	            return Math.random() > 0.5 ? -1 : 1
	        })

	        if (preItem != item) {
	            result = preItem = item
	        
	           } else {
	            result = getRandomItem(arr)
	        }

	        return result
	    }
	}()
	export default {
		name: 'Orgranking',
		data() {
			return {
				renderData: {
					manPeople: 0,
	        		womanPeople: 0,
	        		manRatio: `${0}%`,
	        		womanRatio: `${0}%`,
	        		man: 0,
	        		woman: 0
				}
			}
		},
		methods: {
			getRadomNum() {
                let arr = [35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55]
                return arr[Math.floor(Math.random() * arr.length)]
			},
            getRenderData() {
				getZyzSexApi({}).then((res) => {
					res = res.data
					if (res.code == 200) {
						var people=0,manPeople=0,manRatio=0,womanPeople=0,womanRatio=0
						for(let i=0;i<res.data.length;i++){
							let sex=res.data[i].sex
							let num=res.data[i].num
							let percen=res.data[i].percen;
							if(sex=='未知'){
								people = num
								manPeople = num
								manRatio = percen.slice(0, percen.length- 1)
							} else if(sex=='男'){
								people = people+num
								manPeople = manPeople+num
								manRatio = percen.slice(0, percen.length - 1)
							} else{
								people = people+num
								womanPeople = num
								womanRatio = percen.slice(0, percen.length - 1)
							}
						}
						this.renderData = {
							manPeople,
							womanPeople,
							manRatio: `${manRatio}%`,
							womanRatio: `${womanRatio}%`,
							man: Math.round(manRatio/100*20),
							woman: Math.round(womanRatio/100*20)
						}
					} else {
						alert(res.msg)
					}
				}).catch((err) => {
					console.log(err)
				})
            }
		},
		mounted() {
			this.getRenderData()
			// setInterval(() => {
			// 	this.getRenderData()
			// }, 60000)
		}
	};
</script>

<style scoped>
	.vol-num{
		color: #fff;
		width: 405px;
		height: 256px;
		/*background-color: rgba(23,42,136,0.2);*/
		/*margin-top: 20px;*/
		display: inline-block;
		/*margin-left: 20px*/
	}
	.title{
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		line-height:53px;
		margin-left: 20px;
	}
	.img2{
		float: right;
		width: 20px;
		margin-top: 16px;
		margin-right: 16px;
		cursor: pointer;
	}
	.img1{
		width: 17px;
		margin-right: 5px 
	}
	.ul_inner1{
		list-style: none;
		display: inline-block;
		margin-top: 30px;
		margin-left: 20px;
		vertical-align: middle;
	}
	.ul_inner1 li{
		display: inline-block;
	}
	.inline_block{
		display: inline-block;
		margin-left: 10px;
		vertical-align: middle;
		margin-top: 25px;
	}

	.square_mal{
		background-color:#0ec0e5;
		width: 12px;
		height: 12px;
		margin-right: 10px;
		display: inline-block;
		margin-left: 15px;
	}

	.square_fel{
		background-color:#ffaef7;
		width: 12px;
		height: 12px;
		margin-right: 10px;
		display: inline-block;
		margin-left: 15px;
	}
</style>