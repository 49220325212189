<template>
	<div class="vol-num">
		<span class='title'>志愿者活跃人数</span>
		<img src="../assets/9.png" alt="" class='img2' @click.stop="$emit('on-refresh')">
		<div class='vol-list'>
			<div class='left_inner'>
				<img src="../assets/2.png" class="online-edit">
	            <img src="../assets/4.png" class="online-circle">
				<div class='point' ref="pointData"></div>
			</div>
		</div>
		
	</div>
</template>

<script>
    import echarts from 'echarts'
	export default {
		name: 'activitynum',
		data() {
			return {
			}
		},
		methods: {
			init(){
				var option = {
				    tooltip: {
				        trigger: 'item',
				        formatter: "{a} <br/>{b}: {c} ({d}%)"
				    },
				    legend: {
				        data: ['活跃人数', '不活跃人数'],
				        bottom: 0,
				        textStyle: {
				            color: '#fff'
				        },
				        itemWidth: 16,
				        itemHeight: 16
				    },
				    color: ['#0ec0e5','#053c71'],
				    series: [{
				        name: '访问来源',
				        type: 'pie',
				        selectedMode: 'single',
				        radius: [0, '30%'],
				        label: {
				            normal: {
				                position: 'inner'
				            }
				        },
				        labelLine: {
				            normal: {
				                show: false,
				            }
				        },
				    }, {
				        name: '',
				        type: 'pie',
				        radius: ['45%', '55%'],
				        label: {
				            normal: {
				                textStyle: {
				                    color: '#fff'
				                },
				                formatter: '{a|}{abg|}\n{hr|}\n {per|{d}%} \n{hr|}\n{b|}{c}次   ',
				                borderWidth: 1,
				                borderRadius: 4,
				                rich: {
				                    a: {
				                        color: '#fff',
				                        lineHeight: 22,
				                        align: 'center'
				                    },
				                    hr: {
				                        width: '100%',
				                        borderWidth: 0.5,
				                        height: 0,
				                        color: '#fff',
				                    },
				                    b: {
				                        fontSize: 12,
				                        lineHeight: 33,
				                        color: '#fff',
				                    },
				                    per: {
				                        color: '#fff',
				                        fontSize: 20,
				                        padding: [2, 4],
				                        borderRadius: 2
				                    }
				                }
				            }
				        },
				        labelLine: {
				            normal: {
				                lineStyle: {
				                    color: '#fff',
				                }
				            }
				        },
				        data: [{
				            value: 281136,
				            name: '活跃人数'
				        }, {
				            value: 47357,
				            name: '不活跃人数'
				        } ]
				    }]
				};

				this.myChart = echarts.init(this.$refs.pointData)
				this.myChart.setOption(option);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style scoped>
	.vol-num{
		color: #fff;
		width: 405px;
		height: 259px;
		/*background-color: rgba(23,42,136,0.2);*/
		margin-top: -3px;
		display: inline-block;
		/*margin-left: 20px*/
	}
	.title{
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		line-height:53px;
		margin-left: 20px;
	}

	.point{
		width: 340px;
		height: 220px;
	}
	
	.vol-list{
		text-align: center;
		margin-top: -15px
	}
	.left_inner{
		width: 340px;
		height: 220px;
		position: relative;
		display: inline-block;
		margin-top:-10px;
	}
	.right_inner{
		float: right;
		margin-top:-180px;
		text-align: left;
		padding-right:10px
	}

	.online-edit{
		position: absolute;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
		width:25px;
		height:25px;			
	}
	.online-circle{
		position: absolute;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
		width:170px;
		height:170px ;	
	}
	.img2{
		float: right;
		width: 20px;
		margin-top: 16px;
		margin-right: 16px;
		cursor: pointer;
	}
</style>