<template>
  <div class="vol-num">
    <div>
      <img width="100%" src="@/assets/Group 532@2x.png" alt="" />
    </div>
    <div class="left">
      <div class="btn">
        <table border="0">
          <thead>
            <tr>
              <td width="50"></td>
              <td width="130" >实践站名称</td>
              <td width="120">年度志愿活动数</td>
              <td width="120">志愿者数</td>
            </tr>
          </thead>
          <tbody>
            <tr
              style="line-height: 20px"
              v-for="(item, index) in list"
              :key="index"
            >
              <td>
                <span v-if="index == 0"
                  ><img src="../assets/png_12.png"
                /></span>
                <span v-else-if="index == 1"
                  ><img src="../assets/png_13.png"
                /></span>
                <span v-else-if="index == 2"
                  ><img src="../assets/png_14.png"
                /></span>
                <span class="span" v-else>{{ index + 1 }}</span>
              </td>
              <td @click="showNextEmit(item)" class="pointer">
                {{ item.region }}
              </td>
              <td >{{ item.activity_count }}</td>
              <td >{{ item.vol_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { getcountlist } from "../api";
export default {
  data() {
    return {
      show: false,
      list: [],
    };
  },
  methods: {
    getcountlistapi() {
      getcountlist().then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data;
        }
      });
    },
    showNextEmit() {},
  },
  mounted() {
    this.getcountlistapi();
  },
};
</script>

<style scoped>
.vol-num {
  color: #fff;
  width: 100%;
  height: 400px;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  display: inline-block;
  border: 1px solid #13376c;
  margin-bottom: 10px;
}
.title {
  width: 242px;
  height: 23px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 53px;
  margin-left: 20px;
}
.left {
  display: flex;
}
table {
  width: 100%;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

table thead td {
  height: 30px;
  font-weight: bold;
  font-size: 16px;
}

table img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: auto;
}

table tbody td {
  padding: 5px 0;
  font-size: 16px;
}

table .span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #20a3ff;
  line-height: 16px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  display: block;
  margin: auto;
}
.btn {
  width: 100%;
  overflow: auto;
  height:340px;

}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001336;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #001336;
}
::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  border-radius: 12px;
}
::-webkit-scrollbar {
  height: 4px !important; /*滚动条宽度*/
}
.pointer {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
  display: inline-block;
  font-size: 16px;
}

</style>