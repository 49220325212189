<template>
	<div class="vol-num">
		<span class='title'>志愿者学历</span>
		<img src="../assets/9.png" alt="" class='img2' @click.stop="$emit('on-refresh')">
		<div class='vol-list'>
			<div class='left_inner'>
				<img src="../assets/18.png" class="online-edit">
	            <img src="../assets/4.png" class="online-circle">
				<div class='point' ref="pointData"></div>
			</div>
		</div>
		<!-- <div class="wrapper-box">
			<div class="box">
				<span style="background: #0ec0e5"></span>
				<span>大学专科</span>
			</div>
			<div class="box">
				<span style="background: #053c71"></span>
				<span>初中、技工</span>
			</div>
			<div class="box">
				<span style="background: #036eb8"></span>
				<span>小学</span>
			</div>
			<div class="box">
				<span style="background: #00a199"></span>
				<span>其他</span>
			</div>
			<div class="box">
				<span style="background: #13ae67"></span>
				<span>高中、中专</span>
			</div>
			<div class="box">
				<span style="background: #2ea7e0"></span>
				<span>本科以上</span>
			</div>
		</div> -->
		
	</div>
</template>

<script>
    import echarts from 'echarts'
    import { getZyzEduApi } from '../api/index'

	export default {
		name: 'activitynum',
		data() {
			return {
				list:[],
				seriesData:[]
			}
		},
		methods: {
			async init(){
				await getZyzEduApi({}).then((res) => {
					res = res.data
					if (res.code == 200) {
						this.list=res.data
					} else {
						alert(res.msg)
					}
				}).catch((err) => {
					console.log(err)
				})
				let seriesData=[];
				for(let i=0;i<this.list.length;i++){
					seriesData.push({
						value: this.list[i].num,
				        name: this.list[i].edu_name
					})
				}
				this.seriesData=seriesData
				var option = {
				    tooltip: {
				        trigger: 'item',
				        formatter: "{a}{b}: {c} ({d}%)"
				    },
				    color: ['#0ec0e5','#053c71','#036eb8','#00a199','#13ae67','#2ea7e0'],
				    series: [{
				        name: '访问来源',
				        type: 'pie',
				        selectedMode: 'single',
				        radius: [0, '30%'],
				        label: {
				            normal: {
				                position: 'inner'
				            }
				        },
				        labelLine: {
				            normal: {
				                show: false,
				            }
				        },
				    }, {
				        name: '',
				        type: 'pie',
				        radius: ['40%', '50%'],
				        label: {
				            normal: {
				                textStyle: {
				                    color: '#fff'
				                },
				                formatter: '{a|}{abg|}\n{hr|}\n{per|{d}%} \n{hr|}\n{b|}{c}  ',
				                borderWidth: 1,
				                borderRadius: 4,
				                rich: {
				                    a: {
				                        color: '#fff',
				                        lineHeight: 0,
				                        align: 'center'
				                    },
				                    hr: {
				                        width: '100%',
				                        borderWidth: 0.5,
				                        height: 0,
				                        color: '#fff',
				                    },
				                    b: {
				                        fontSize: 12,
				                        lineHeight: 0,
				                        color: '#fff',
				                    },
				                    per: {
				                        color: '#fff',
				                        fontSize: 16,
				                        padding: [2, 4],
				                        borderRadius: 2
				                    }
				                }
				            }
				        },
				        labelLine: {
				            normal: {
				                lineStyle: {
				                    color: '#fff',
				                },
				            }
				        },
				        data: this.seriesData
				    }]
				};
				this.myChart = echarts.init(this.$refs.pointData)
				this.myChart.setOption(option);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style scoped>
    .wrapper-box {
    	position: absolute;
    	width: 100%;
    	height: 25px;
    	bottom: 0px;
    	left: 0px;
    	/*border: 1px solid red;*/
    	font-size: 12px;
    	display: flex;
    	justify-content: center;
    	align-items: center;
    }
    .wrapper-box .box {
    	display: flex;
    	margin-right: 8px;
    	align-items: center;
    }
    .wrapper-box .box span:nth-child(1) {
    	width: 12px;
		height: 12px;
		border-radius: 2px;
		display: inline-block;
		background: red;
		margin-right: 2px;

    }

	.vol-num{
		color: #fff;
		width: 405px;
		height: 259px;
		/*background-color: rgba(23,42,136,0.2);*/
		margin-top:-3px;
		display: inline-block;
		/*margin-left: 20px*/
		position: relative;
	}
	.title{
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		line-height:53px;
		margin-left: 20px;
	}

	.point{
		width: 340px;
		height: 229px;
	}
	
	.vol-list{
		text-align: center;
		margin-top: -15px
	}
	.left_inner{
		width: 340px;
		height: 229px;
		position: relative;
		display: inline-block;
		margin-top:-12px;
	}
	.right_inner{
		float: right;
		margin-top:-180px;
		text-align: left;
		padding-right:10px
	}

	.online-edit{
		position: absolute;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
		width:25px;
		height:25px;			
	}
	.online-circle{
		position: absolute;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
		width:170px;
		height:170px ;	
	}
	.img2{
		float: right;
		width: 20px;
		margin-top: 16px;
		margin-right: 16px;
		cursor: pointer;
	}
</style>