<template>
	<div class="show-platform">
		<p class="title">{{ title }}</p>
		<div class="content-box">
			{{ num }}
			<!-- <animated-integer :value="num" /> -->
		</div>
	</div>
</template>

<script>
var getRandomItem = function () {
	var preItem = null

	return function (arr) {

		var index = Math.floor(Math.random() * arr.length),
			item = arr[index],
			result

		arr = arr.sort(function () {
			return Math.random() > 0.5 ? -1 : 1
		})

		if (preItem != item) {
			result = preItem = item

		} else {
			result = getRandomItem(arr)
		}

		return result
	}
}()

export default {
	name: 'ShowPlatform',
	props: {
		title: {
			type: String,
			default: ''
		},
		time: {
			type: Number,
			default: 1000
		},
		number: {
			type: Number,
			default: 1000
		}
	},
	data() {
		return {
			num: this.number
		}
	},
	methods: {
		getRadomNum() {
			let arr = [0, 1, 2]
			return arr[Math.floor(Math.random() * arr.length)]
		},
		getNum() {
			this.num = this.num
		},
		getCurNum() {
			return this.num
		}
	},
	mounted() {
		setInterval(() => {
			this.getNum()
		}, this.time)
	}
};
</script>

<style scoped>
.show-platform {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-left: 50px;
}

.title {
	font-size: 14px;
	font-family: FZLanTingHei-R-GBK;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
}

.content-box {
	width: 140px;
	height: 140px;
	margin-top: 12px;
	background: url(../assets/gif1.gif);
	background-size: contain;
	text-align: center;
	line-height: 140px;
	font-size: 35px;

}
</style>