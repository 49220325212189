<template>
	<div class="vol-num">
		<span class='title'>服务点单</span>
		<div class='vol-list'>
			<div class='left_inner'>
				<img src="../assets/17.png" class="online-edit">
				<img src="../assets/4.png" class="online-circle">
				<div class='point' ref="pointData"></div>
			</div>
			<div class='right_inner'>
				<p style="font-size:16px;width:150px;">服务点单总量</p>
				<div class="show-number">
					<span v-for="(num, index) in mass_num" :key="index" class="span-box1">
						{{ num }}
						<!-- <animated-integer :value="num" /> -->
					</span>
				</div>
				<!-- <show-num title="" :number="[2,0,1]" :time="60*1000" :tick="false" /> -->
			</div>
			<div
				style="height: 40px;margin-top: -13px;float: left;width: 100%;text-align: center; display: flex; justify-content: space-between;">
				<div class='listInner2' style="background: #0ec0e5">
					<span>接单</span>
					<span style="font-size:24px;margin-left:20px;">{{ n1 }}</span>
				</div>
				<div class='listInner2' style="background: #053c71">
					<span>派单</span>
					<span style="font-size:24px;margin-left:20px;">{{ n2 }}</span>
				</div>
				<div class='listInner2' style="background: #3090AE">
					<span>转单</span>
					<span style="font-size:24px;margin-left:20px;">{{ n3 }}</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import echarts from 'echarts'
import ShowNum from './show-num'

import {
	getMassNumApi,
	getBaseNumApi
} from '../api/index'

export default {
	name: 'serverpoint',
	data() {
		return {
			mass_num: [],
			n1: 758,
			n2: 570,
			n3: 178
		}
	},
	methods: {
		numGoArray(n) {
			var str = n + "";
			var arr = [];
			str.split("").forEach(function (item) {
				arr.push(parseInt(item));
			})
			return arr;
		},
		async getMassNum() {
			let res1 = await getMassNumApi({})
			let res2 = await getBaseNumApi({})
			let mass_num = res1.data.data.mass_num;
			mass_num = mass_num + res2.data.data[0].mass_num;
			this.mass_num = this.numGoArray(mass_num)
		},
		init() {
			var option = {
				tooltip: {
					trigger: 'item',
					formatter: "{a}{b}: {c} ({d}%)"
				},
				color: ['#053c71', '#0ec0e5', '#3090AE'],
				series: [{
					name: '访问来源',
					type: 'pie',
					selectedMode: 'single',
					radius: [0, '30%'],
					label: {
						normal: {
							position: 'inner'
						}
					},
					labelLine: {
						normal: {
							show: false,
						}
					},
				}, {
					name: '',
					type: 'pie',
					radius: ['45%', '55%'],
					label: {
						normal: {
							textStyle: {
								color: '#fff'
							},
							formatter: '{a|}{abg|}\n{hr|}\n  {per|{d}%} \n{hr|}\n{b|}{c}次   ',
							borderWidth: 1,
							borderRadius: 4,
							rich: {
								a: {
									color: '#fff',
									lineHeight: 22,
									align: 'center'
								},
								hr: {
									width: '100%',
									borderWidth: 0.5,
									height: 0,
									color: '#fff',
								},
								b: {
									fontSize: 12,
									lineHeight: 33,
									color: '#fff',
								},
								per: {
									color: '#fff',
									fontSize: 20,
									padding: [2, 4],
									borderRadius: 2
								}
							}
						}
					},
					labelLine: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
							length: 2
						}
					},
					data: [{
						value: this.n2,
						name: '派单'
					}, {
						value: this.n1,
						name: '接单'
					}, {
						value: this.n3,
						name: '转单'
					}]
				}]
			};

			this.myChart = echarts.init(this.$refs.pointData)
			this.myChart.setOption(option);
		},
		getRadomNum() {
			let arr = [1, 2]
			return arr[Math.floor(Math.random() * arr.length)]
		},
	},
	mounted() {
		this.init();
		this.getMassNum();
		// setInterval(() => {
		// 	this.n1 = Number(this.n1) +  this.getRadomNum()
		// 	this.$nextTick(() => {
		// 		this.init()
		// 	})
		// }, 100 * 1000)

		// setInterval(() => {
		// 	this.n2 = Number(this.n2) +  this.getRadomNum()
		// 	this.$nextTick(() => {
		// 		this.init()
		// 	})
		// }, 120 * 1000)
		// this.timer = setInterval(() => {
		// 	this.init();
		// 	this.getMassNum();
		// }, 120000);
	},
	beforeDestroy() {
		// clearInterval(this.timer);
	}
};
</script>

<style scoped>
.vol-num {
	color: #fff;
	width: 405px;
	height: 259px;
	background-color: rgba(23, 42, 136, 0.2);
	margin-top: 17px;
	display: inline-block;
	margin-left: 20px
}

.title {
	font-size: 22px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
	line-height: 53px;
	margin-left: 20px;
}

.point {
	width: 290px;
	height: 180px;
}

.left_inner {
	width: 290px;
	height: 180px;
	position: relative;
	display: inline-block;
	margin-top: -10px;
}

.right_inner {
	float: right;
	margin-top: -210px;
	text-align: left;
	padding-right: 10px
}

.online-edit {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 25px;
	height: 25px;
}

.online-circle {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 150px;
	height: 150px;
}

.span-box1 {
	background: #05C2E6 !important;
	width: 18px;
	height: 32px;
	display: inline-block;
	text-align: center;
	font-size: 20px;
	font-family: FZLanTingHei-R-GBK;
	font-weight: 400;
	color: #fff;
	line-height: 32px;
	margin-right: 6px;
	margin-top: 20px;
}

.listInner1 {
	font-size: 16px;
	color: #fff;
	width: 170px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	display: inline-block;
}

.listInner2 {
	font-size: 16px;
	color: #fff;
	/*width: 170px;*/
	width: 130px;
	height: 40px;
	background-color: rgba(41, 171, 226, 0.2);
	text-align: center;
	line-height: 40px;
	display: inline-block;
}
</style>