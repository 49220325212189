<template>
	<div class="vol-num">
		<span class='title'>志愿者活动时长排名(本月)</span>
		<img src="../assets/9.png" alt="" class='img2' @click.stop="$emit('on-refresh')">
		<div class='vol-list'>
			<div class='inner1'>
				<div class='inner_list1'>姓名</div>
				<!-- <div class='inner_list1'>组织名称</div> -->
				<div class='inner_list2'>本月服务时长</div>
				<div class='inner_list3'>所属区域</div>
			</div>
			<ul class='ul_list'>
				<li v-for="(item, index) in list" :key="index">
					<div>
						<div class='inner_list0'>
							<span class='spot'>{{ index + 1 }}</span>
							<span class='title2'>{{ item.name }}</span>
						</div>
						<div class='inner_list2'>{{ item.servicetime }}</div>
						<div class='inner_list3'>{{ item.area5_name?item.area5_name:item.area4_name?item.area4_name:item.area3_name }}</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import { getZyzRelRankingApi } from '../api/index'
    import echarts from 'echarts'
    var getRandomItem = function () {
	    var preItem = null

	    return function (arr) {

	        var index = Math.floor(Math.random() * arr.length),
	            item = arr[index],
	            result

	        arr = arr.sort(function() { 
	            return Math.random() > 0.5 ? -1 : 1
	        })

	        if (preItem != item) {
	            result = preItem = item
	        
	           } else {
	            result = getRandomItem(arr)
	        }

	        return result
	    }
	}()

	export default {
		name: 'Orgranking',
		data() {
			return {
                list: []
			}
		},
		methods: {
			dateFormat(time) {
				var date=new Date(time);
				var year=date.getFullYear();
				/* 在日期格式中，月份是从0开始的，因此要加0
				* 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
				* */
				var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
				var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
				var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
				var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
				var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
				// 拼接
				return year+"-"+month+"-"+day
			},
			getList() {
				this.list = Array.from({ length: 5 }).map(m => getRandomItem(list))
			},
			init() {
				var now = new Date(); //当前日期 
				var nowMonth = now.getMonth(); //当前月 
				var nowYear = now.getFullYear(); //当前年 
				//本月的开始时间
				var monthStartDate = new Date(nowYear, nowMonth, 1); 
				//本月的结束时间
				var monthEndDate = new Date(nowYear, nowMonth+1, 0);
				let form= new URLSearchParams()
				form.append('begintime',  this.dateFormat(monthStartDate))
				form.append('endtime',  this.dateFormat(monthEndDate))
				form.append('num',  5)
				getZyzRelRankingApi(form).then((res) => {
					res = res.data
					if (res.code == 200) {
						this.list=res.data
					} else {
						alert(res.msg)
					}
				}).catch((err) => {
					console.log(err)
				})
			},
		},
		mounted() {
			this.init()
			// this.timer = setInterval(this.init, 120000);
		},
		beforeDestroy() {
	    	// clearInterval(this.timer);
	    }
	};
</script>

<style scoped>
	.vol-num{
		color: #fff;
		width: 405px;
		height: 259px;
		/*background-color: rgba(23,42,136,0.2);*/
		/*margin-top: -10px;*/
		display: inline-block;
	}
	.spot {
	    display: inline-block;
	    width: 18px;
	    height: 18px;
	    text-align: center;
	    line-height: 20px;
	    background: #009fe8;
	    color: #fff;
	    border-radius: 50%;
	    margin-left: 10px;
	}
	.title{
		font-size:22px;
		font-family:Microsoft YaHei;
		font-weight:400;
		color:rgba(255,255,255,1);
		line-height:53px;
		margin-left: 20px;
	}
	.broken-line{
		width: 405px;
		height:260px;
	}
	.title2{
		font-size: 14px;
		color: #fff;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
		white-space: nowrap;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    width: 97px;					
	}

	.ul_list{
		list-style: none
	}

	.ul_list li{
		height: 34px;
		line-height: 34px;
		background-color: rgba(23,42,136,0.1);
	}
	.ul_list li:nth-of-type(2n){
		height: 34px;
		line-height: 34px;
		background-color: rgba(75,162,249,0.1);
	}

	.inner1{
		height: 34px;
		line-height: 34px;
		background-color: rgba(75,162,249,0.1);
	}
	.inner_list1{
		width: 150px;
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		color: #fff;
		text-align: left;
		padding-left: 10px;
		box-sizing: border-box;
	}
	.inner_list2{
		width: 100px;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 14px;
		color: #fff;
	}

	.inner_list3{
		width: 150px;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 14px;
		color: #fff;
		white-space: nowrap;
	    text-overflow: ellipsis;
	    overflow: hidden;
	}
	.inner_list4{
		width: 150px;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		font-size: 14px;
		color: #fff;
	}
	.inner_list0{
		width: 150px;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		font-size: 14px;
		color: #fff;
	}
	.img2{
		float: right;
		width: 20px;
		margin-top: 16px;
		margin-right: 16px;
		cursor: pointer;
	}
</style>