<template>
  <div class="vol-num">
    <span class="title">
      <img width="100%" src="@/assets/Group 620@2x.png" alt="" />
    </span>
    <div class="btns">
      <div class="vol-list">
        <div class="left_inner">
          <!-- <div class="point" ref="pointData"></div> -->
          <dv-active-ring-chart
            v-if="show"
            :config="config"
            style="width: 100%; height: 80%"
          />
        </div>
      </div>
      <volunteerService />
    </div>
  </div>
</template>

<script>
import VolunteerService from "./volunteer-service.vue";
import { getCountListApi } from "../api/index";
import echarts from "echarts";
export default {
  name: "activitynum",
  components: {
    VolunteerService,
  },
  data() {
    return {
      list: [],
      color: ["#009fe8", "#00a199", "#3090ae", "#0ec0e5", "#053c71"],
      seriesData: [],
      config: {
        data: [],
        digitalFlopStyle: {
          fontSize: 10,
          fill: "#fff",
        },
        showOriginValue: true,
      },
      show: false,
    };
  },
  methods: {
    async init() {
      let form = new URLSearchParams();
      form.append("type", 6);
      await getCountListApi(form)
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.list = res.data.data;
            // console.log(this.list)
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      let seriesData = [];
      for (let i = 0; i < this.list.length; i++) {
        seriesData.push({
          value: this.list[i].num,
          name: this.list[i].name,
          label: {
            normal: {
              textStyle: {
                color: "#fff",
              },
              // formatter:
              //   "{a|}{abg|}\n{hr|}\n{per|{d}%} \n{hr|}\n{b|" +
              //   this.list[i].name +
              //   "}\n{c}人",
              formatter: "{b|" + this.list[i].name + "}\n{c}人",
              borderWidth: 0.3,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#fff",
                  lineHeight: 12,
                  align: "left",
                },
                hr: {
                  width: "100%",
                  borderWidth: 0.5,
                  height: 0,
                  color: "#fff",
                },
                b: {
                  fontSize: 12,
                  lineHeight: 10,
                  color: "#fff",
                },
                per: {
                  color: "#fff",
                  fontSize: 12,
                  padding: [1, 2],
                  borderRadius: 2,
                },
              },
            },
          },
          itemStyle: {
            normal: {
              color: this.color[i],
            },
          },
        });
      }
      this.seriesData = seriesData;
      this.config.data = this.seriesData;
      this.show = true;
      // var option = {
      //   backgroundColor: "transparent",
      //   title: {
      //     text: "",
      //     left: "center",
      //     top: 20,
      //     textStyle: {
      //       color: "#ccc",
      //     },
      //   },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{b}：{c}人 <br/>{d}%",
      //   },
      //   series: [
      //     {
      //       name: "访问来源",
      //       type: "pie",
      //       radius: "70%",
      //       center: ["50%", "50%"],
      //       data: seriesData.sort(function (a, b) {
      //         return a.value - b.value;
      //       }),
      //       roseType: "radius",
      //       label: {
      //         normal: {
      //           textStyle: {
      //             color: "rgba(255, 255, 255, 1)",
      //           },
      //         },
      //       },
      //       labelLine: {
      //         normal: {
      //           lineStyle: {
      //             color: "rgba(255, 255, 255, 0.3)",
      //           },
      //           smooth: 0.2,
      //           length: 0,
      //           length2: 5,
      //         },
      //       },
      //       itemStyle: {
      //         normal: {
      //           // color: '#053c71',
      //           // shadowBlur: 200,
      //           // shadowColor: 'rgba(0, 0, 0, 0.5)'
      //         },
      //       },
      //       animationType: "scale",
      //       animationEasing: "elasticOut",
      //       animationDelay: function (idx) {
      //         return Math.random() * 200;
      //       },
      //     },
      //   ],
      // };

      // this.myChart = echarts.init(this.$refs.pointData);
      // this.myChart.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.vol-num {
  color: #fff;
  width: 60%;
  height: 332px;
  display: inline-block;
  background: #001d4f;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  opacity: 0.8;
  border: 1px solid #13376c;
  margin-right: 10px;
  background-color: #021347;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  /* line-height: 53px; */
}

.point {
  width: 60%;
  height: 100%;
}
.bottom {
  width: 100%;
  height: 20px;
  background-size: 100% 100%;
  background-image: url(../assets/png_10.png);
}
.vol-list {
  width: 60%;
}
.left_inner {
  width: 100%;
  height: 210px;
  position: relative;
  display: inline-block;
}
.btns {
  margin-top: 20px;
  display: flex;
}
</style>
