<template>
  <div class="panel">
    <div class="information">
      <div v-if="refreshPanel" style="width:100%;margin-top: 15px">
        <show-num title="累计服务时长" style="margin-top: 15px" :number="zyz_service_num" :time="10 * 1000" :tick="false"
          :digit="8" :addNum="zyz_service_add_num" />
        <!-- zyz_service_now_count_year    -->
        <show-num style="margin-top: 15px" title="年度服务时长" :number="zyz_service_now_count_year" :tick="false"
          :time="20000" :digit="8" />
      </div>
      <div v-if="!refreshPanel">
        <show-num title="累计志愿活动数" style="margin-top: 15px" :number="activity_num" :time="10 * 1000" :digit="7" />
        <show-num style="margin-top: 15px" title="年度志愿活动数" :number="activity_num_year" :time="10 * 1000" :digit="7" />
      </div>
      <img src="../assets/9.png" alt="" class="refresh" style="margin-top: 18px" @click.stop="switchPanel" />
    </div>
  </div>
</template>

<script>
import ShowNum from "./show-num";
import {
  getBaseNumApi,
  getActivityNumApi,
  getZyzNumApi,
  getCountListApi,
  getPlanNumApi,
  getActivityServicetimeNumApi,
} from "../api/index";
export default {
  components: {
    ShowNum,
  },
  data() {
    return {
      baseNum: "", //基础数量
      refreshPanel: true,
      zyz_service_num: [0],
      zyz_service_now_count_year: [0], //年度服务时长
      activity_num: [0],
      activity_num_year: [0],
      zyz_service_add_num: 0,
    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    switchPanel() {
      this.refreshPanel = !this.refreshPanel;
    },
    numGoArray(n) {
      var str = n + "";
      var arr = [];
      str.split("").forEach(function (item) {
        arr.push(parseInt(item));
      });
      return arr;
    },
    getZyzServiceNum() {
      let form = new URLSearchParams();
      form.append("type", 1);
      getCountListApi(form)
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            //累计服务时长
            // console.log(res.data.data[0].zyz_servicecount);
            this.zyz_service_num = this.numGoArray(
              parseInt(res.data.data[0].zyz_servicecount)
              // (this.baseNum.zyz_servicecount
              //   ? this.baseNum.zyz_servicecount
              //   : 0)
            );
            //年度服务时长
            this.zyz_service_now_count_year = this.numGoArray(
              parseInt(res.data.data[0].zyz_service_now_count_year)
            );
            // 累计志愿活动数
            this.activity_num = this.numGoArray(
              parseInt(res.data.data[0].all_activity_count)
            );
            //年度志愿活动数
            this.activity_num_year = this.numGoArray(
              parseInt(res.data.data[0].year_activity_count)
            );
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivityServicetimeNum() {
      getActivityServicetimeNumApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            let data = res.data[0];
            this.zyz_service_add_num = res.data.activity_servicetime_now;
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // async getActivityNum() {
    //   let res1 = await getActivityNumApi({});
    //   let res2 = await getBaseNumApi({});
    //   let activity_num = res1.data.data[0].count;
    //   activity_num = activity_num + res2.data.data[0].activity_num;
    //   this.activity_num = this.numGoArray(activity_num);
    //   this.activity_num_year = this.numGoArray(res1.data.data[0].yearcount);
    // },
    async getAllApi() {
      // let res1 = await getBaseNumApi({});
      // this.baseNum = res1.data.data[0];
      // this.getActivityServicetimeNum();
      this.getZyzServiceNum();
      // this.getActivityNum();
    },
  },
  created() {
    this.getAllApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.panel {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

}

.panel>div {
  height: 100%;
  width: 100%;
  border: none;
}

.information {
  position: relative;

}

.refresh {
  position: absolute;
  top: 2px;
  right: 20px;
  width: 15px;
  height: 16px;
  /* margin-top: -25px; */
  /* margin-right: 20px; */
  cursor: pointer;
}
</style>
