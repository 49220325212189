<template>
  <div class="show-numbers">
    <p class="title">
      {{ title }}
      <span style="font-size: 10px;">{{ note }}</span>
    </p>
    <span v-for="(num, index) in list" :key="index" class="span-box" :class="klass">
      <span>{{ num }}</span>
      <!-- <animated-integer :value="num" /> -->
    </span>
  </div>
</template>

<script>
export default {
  name: "ShowNumber",
  props: {
    digit: {
      type: Number,
      default: 7,
    },
    title: {
      type: String,
      default: "志愿者总数量",
    },
    note: {
      type: String,
      default: "",
    },
    time: {
      type: Number,
      default: 1000,
    },
    klass: {
      type: String,
      default: "",
    },
    number: {
      type: Array,
      default: function () {
        return [1, 0, 0, 0, 0, 0];
      },
    },
    tick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: [],
      numArr: [],
    };
  },
  watch: {
    number(val) {
      this.init();
    },
  },
  methods: {
    getRadomNum() {
      let arr = [1, 2, 3, 4, 5];
      return arr[Math.floor(Math.random() * arr.length)];
    },
    getList() {
      return Array.from({ length: this.digit }).map((m) => this.getRadomNum());
    },
    add(first) {
      let num = parseInt(this.numArr.join(""));
      let preNum = num;
      if (!first) {
        num += this.getRadomNum();
      }
      // console.log(preNum, num)
      // console.log('`````````````````````````')
      let len = String(num).length;
      if (len < this.digit) {
        for (let i = 0; i < this.digit - len; i++) {
          num = "0" + num;
        }
      }
      try {
        this.numArr = this.list = num.split("");
      } catch (error) { }
    },
    init() {
      this.list = this.numArr = this.number;
      console.log(this.list);
      setInterval(() => {
        if (this.tick) {
          this.add();
        } else {
          this.list = [0, 0, 0, 0, 0, 0, 0, 0];
          this.$nextTick(() => {
            let num = this.number.join("");
            let len = String(num).length;
            if (len < this.digit) {
              for (let i = 0; i < this.digit - len; i++) {
                num = "0" + num;
              }
            }
            this.list = num.split("");
            console.log(this.list);
          });
        }
      }, this.time);
      this.add("first");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.show-number {}

.title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 5px;
  /* margin-left: 10px; */
}

.span-box {
  background: rgba(0, 184, 255, 0.4);
  width: 15px;
  height: 30px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  font-family: FZLanTingHei-R-GBK;
  font-weight: 400;
  color: #71d0ff;
  line-height: 30px;
  margin-right: 6px;
}
</style>
