<template>
  <div id="app">
    <div class="app_inner">
      <div class="nowData">
        <!-- <div class="online-time align_right"><img src="./assets/8.png" alt=""><span ref='online-time'>{{nowTime}}</span></div> -->
      </div>
      <div class="inner-header"></div>
      <div class="main_body_inner" style="padding: 0 35px">
        <!-- <div class="header-box" id="box"> -->
        <!-- <show-num title="志愿者总数量" :number="zyz_num" :time="8 * 1000" :tick="false" /> -->
        <!-- <show-num title="累计志愿服务时长" :number="activity_servicetime" :time="10 * 1000" :tick="false" /> -->
        <!-- <show-num title="志愿组织总数量" :number="org_num" :time="5 * 1000" :tick="false" /> -->

        <!-- </div> -->
        <div class="middle_inner">
          <div class="inline_block vertical_top" style="width: 29%">
            <vol-num></vol-num>
            <serviceorder-Rank></serviceorder-Rank>
          </div>
          <div class="inline_block vertical_top" style="width: 40%">
            <div class="header-box" id="box">
              <div class="statistics-box">
                <p>实践所</p>
                <!-- {{ statisticsObj.regionCount }} -->
                <p>18</p>
              </div>
              <div class="statistics-box">
                <p>实践站</p>
                <!-- {{ statisticsObj.areaCount }} -->
                <p>402</p>
              </div>
              <div class="statistics-box">
                <p>平台管理人员</p>
                <p>{{ adminCount }}</p>
              </div>
              <div class="statistics-box">
                <p>志愿者团队</p>
                <p>{{ statisticsObj.org_num }}</p>
              </div>
              <div class="statistics-box">
                <p>志愿者人数</p>
                <p>{{ statisticsObj.zyz_num }}</p>
              </div>
              <!--                <show-num-->
              <!--                  title="志愿者总数量"-->
              <!--                  :number="zyz_num"-->
              <!--                  :time="18 * 1000"-->
              <!--                  :tick="false"-->
              <!--                />-->
              <!--                <show-num-->
              <!--                  title="志愿组织总数量"-->
              <!--                  :number="org_num"-->
              <!--                  :time="15 * 1000"-->
              <!--                  :tick="false"-->
              <!--                />-->
              <!--                <show-num-->
              <!--                  title="新时代文明实践中心"-->
              <!--                  note="（所、站、点）"-->
              <!--                  :number="region_num"-->
              <!--                  :time="10 * 1000"-->
              <!--                  :tick="false"-->
              <!--                />-->
            </div>
            <div>
              <map-polygon ref="mapPolygon" @showRight="showRight()" @changeMap="changeMap()"></map-polygon>
            </div>
            <ScrollContent></ScrollContent>
          </div>
          <div class="inline_block vertical_top" style="width: 29%">
            <div class="vol-num2">
              <PracticeRank ref="practiceRankComp" @changeHide="changeHide()" @showInnerEmit="showInnerEmit"
                @changeMap="changeRank()" />
            </div>
            <div class="vol-num2s">
              <vol-Rightmiddom></vol-Rightmiddom>
              <volZhuxingtu></volZhuxingtu>
            </div>
            <!-- <server-point></server-point> -->
            <div class="vol-num2s">
              <core />
            </div>
            <div>
              <div class="modal_bg" v-if="ifShow" @click="showModal(false)">
                <el-carousel :interval="4000" trigger="click" arrow="always" :autoplay="false">
                  <el-carousel-item v-for="(item, index) in imgList" :key="index">
                    <img :src="completionUrl(item)" class="modal" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import core from "./components/core";
import volZhuxingtu from "./components/vol-zhuxingtu";
import volRight from "./components/vol-right";
import volRightmiddom from "./components/vol-rightmiddom";
import VolNum from "./components/vol-num";
import ShowNum from "./components/show-num";
import OrgRanking from "./components/org-ranking";
import ShowPlatform from "./components/show-platform";
import TotalNumberOfActivities from "./components/total-number-of-activities";
import OrderToday from "./components/order-today";
import Gender from "./components/gender";
import ServerPoint from "./components/server-point";
import ActivityNum from "./components/activity-num";
import ActivityYear from "./components/activity-year";
import ActivityEducation from "./components/activity-education.vue";
import MapPolygon from "./components/map-polygon";
import ActivityRanking from "./components/activity-ranking";
import Activity from "./components/activity";
import Carousel from "./components/carousel";
import PracticeRank from "./components/practice-rank";
import ScrollContent from "./components/scroll-content";
import serviceorderRank from "./components/serviceorder-rank.vue";
import {
  getActivityServicetimeNumApi,
  getRegionNumApi,
  getZyzNumApi,
  getOrgNumApi,
  getBaseNumApi,
  getActivityTypeApi,
  getStatisticsApi,
} from "./api/index";
let keyNum = 100;

var getRandomItem = (function () {
  var preItem = null;

  return function (arr) {
    var index = Math.floor(Math.random() * arr.length),
      item = arr[index],
      result;

    arr = arr.sort(function () {
      return Math.random() > 0.5 ? -1 : 1;
    });

    if (preItem != item) {
      result = preItem = item;
    } else {
      result = getRandomItem(arr);
    }

    return result;
  };
})();

let arr = [
  {
    title: "理论宣讲平台",
    number: 4780,
    time: 60 * 1000,
  },
  {
    title: "教育服务平台",
    number: 3792,
    time: 80 * 1000,
  },
  {
    title: "文体服务平台",
    number: 4894,
    time: 58 * 1000,
  },
  {
    title: "科技科普平台",
    number: 2846,
    time: 58 * 1000,
  },
  {
    title: "健康促进平台",
    number: 3866,
    time: 78 * 1000,
  },
];

let countDown = 0;
let ticker = null;

export default {
  name: "app",
  components: {
    VolNum,
    ShowNum,
    ShowPlatform,
    TotalNumberOfActivities,
    OrderToday,
    OrgRanking,
    Gender,
    ServerPoint,
    ActivityNum,
    ActivityYear,
    ActivityEducation,
    ServerPoint,
    MapPolygon,
    ActivityRanking,
    Activity,
    volRight,
    volRightmiddom,
    volZhuxingtu,
    core,
    Carousel,
    PracticeRank,
    ScrollContent,
    serviceorderRank,
  },
  data() {
    return {
      nowTime: "",
      curComp: "Gender",
      forComps: ["Gender", "ActivityNum", "ActivityYear", "ActivityEducation"],
      curComp2: "ActivityRanking",
      twoComps: ["ActivityRanking", "OrgRanking"],
      listComp: [],
      rightShow: 0,
      activity_num: [],
      region_num: [],
      zyz_num: [],
      org_num: [],
      ifShow: false,
      activity_servicetime: [],
      baseNum: "",
      statisticsObj: {
        areaCount: 0,
        orgCount: 0,
        regionCount: 0,
        zyzCount: 0,
      },
      adminCount: 0,
      typeList: [
        {
          value1: "",
          num: 0,
        },
        {
          value1: "",
          num: 0,
        },
      ],
      active: 1,
    };
  },
  methods: {
    numGoArray(n) {
      var str = n + "";
      var arr = [];
      str.split("").forEach(function (item) {
        arr.push(parseInt(item));
      });
      return arr;
    },
    showInnerEmit() { },
    getRegionNum() {
      getRegionNumApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.region_num = this.numGoArray(res.data.region_num);
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeRank() {
      // let rankName = this.$refs.practiceRankComp.itemInfo;
      // let result = rankName;
      // result.name = result.name ? result.name.substring(0, 4) : "";
      // result.data = { id: result.id };
      // this.$refs.mapPolygon.changeMap(result);
      // this.$refs.mapPolygon.showNext = !this.ifChange;
      // this.$refs.practiceRankComp.ifShow = !this.ifChange;
      // this.ifChange = !this.ifChange;
    },
    getZyzNum() {
      getZyzNumApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.zyz_num = this.numGoArray(
              res.data.zyz_num + this.baseNum.zyznum
            );
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showRight(info) {
      this.regionlevel = this.$refs.mapPolygon.regionlevel;
      if (this.regionlevel == 3) {
        this.regionid = this.$refs.mapPolygon.regionid;
      } else {
        this.area = this.$refs.mapPolygon.area;
      }
      this.rightShow = 2;
      this.$refs.actDetailComp ? this.$refs.actDetailComp.getBase() : "";
    },
    changeMap() {
      let mapName = this.$refs.mapPolygon.params;

      let list = this.$refs.practiceRankComp.list;
      let result = "";
      for (let i = 0; i < list.length; i++) {
        if (list[i].name.indexOf(mapName.name) != -1) {
          result = list[i];
        }
      }
      this.$refs.practiceRankComp.showNext(result);

      this.$refs.mapPolygon.showNext = !this.ifChange;
      this.$refs.practiceRankComp.ifShow = !this.ifChange;
      this.ifChange = !this.ifChange;
    },
    getOrgNum() {
      getOrgNumApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.org_num = this.numGoArray(
              res.data.org_num + this.baseNum.orgnum
            );
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivityServicetimeNum() {
      getActivityServicetimeNumApi({})
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.activity_servicetime = this.numGoArray(
              parseInt(
                res.data.activity_servicetime + this.baseNum.activity_time
              )
            );
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivityType() {
      var now = new Date(); //当前日期
      var nowYear = now.getFullYear(); //当前年
      let form = new URLSearchParams();
      form.append("begintime", nowYear + "-01-01");
      form.append("endtime", nowYear + "-12-31");
      getActivityTypeApi(form)
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            this.typeList = data.data;
          } else {
            alert(data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeTypeList() {
      this.active++;
      if (this.active * 2 > this.typeList.length) {
        this.active = 1;
      }
    },

    key() {
      if (countDown == 0) {
        countDown = 15;
        clearInterval(ticker);
        ticker = setInterval(() => {
          countDown--;
          if (countDown <= 0) {
            countDown = 0;
            clearInterval(ticker);
          }
        }, 1000);
        return ++keyNum;
      } else {
        return keyNum;
      }
    },
    changeComp() {
      let i = this.forComps.findIndex((m) => m === this.curComp);
      let len = this.forComps.length;
      if (i == len - 1) {
        i = 0;
      } else if (i < len) {
        ++i;
      }
      this.curComp = this.forComps[i];
    },
    changeComp2() {
      let i = this.twoComps.findIndex((m) => m === this.curComp2);
      let len = this.twoComps.length;
      if (i == len - 1) {
        i = 0;
      } else if (i < len) {
        ++i;
      }
      this.curComp2 = this.twoComps[i];
    },
    initInterval1() {
      //   	clearInterval(this.t1)
      //   	this.t1 = setInterval(() => {
      // 	this.changeComp()
      // }, 8000)
    },
    initInterval2() {
      //   	clearInterval(this.t2)
      //   	this.t2 = setInterval(() => {
      // 	this.changeComp2()
      // }, 6000)
    },
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      this.nowTime =
        year +
        "年" +
        month +
        "月" +
        date +
        "日" +
        " " +
        hh +
        ":" +
        mm +
        ":" +
        ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },
    async getAllApi() {
      let res1 = await getBaseNumApi({});
      this.baseNum = res1.data.data[0];
      this.getRegionNum();
      this.getZyzNum();
      this.getOrgNum();
      this.getActivityServicetimeNum();
      this.getActivityType();
    },
    getStatisticsNum(type) {
      let form = new URLSearchParams();
      form.append("type", type);
      getStatisticsApi(form).then((res) => {
        // this.statisticsObj = res.data.data;
        this.statisticsObj = res.data.data.data[0];
      });
    },
    //获取平台管理员人数
    getAdminCount() {
      let form = new URLSearchParams();
      form.append("type", 12);
      getStatisticsApi(form).then((res) => {
        this.adminCount = res.data.data.adminCount;
      });
    },
  },
  mounted() {
    // this.initInterval1()
    // this.initInterval2()
    this.nowTimes();
    this.getAllApi();
    this.getStatisticsNum(1);
    this.getAdminCount();
    // this.timer = setInterval(this.getAllApi, 120000);
    // this.timer2 = setInterval(this.changeTypeList, 2000);
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
};
</script>

<style scoped>
.vol-num1 {
  height: 259px;
  background-color: rgba(23, 42, 136, 0.2);
  margin-top: -13px;
}

.vol-num2 {
  background-color: rgba(23, 42, 136, 0.2);
}

.vol-num2s {
  background-color: rgba(23, 42, 136, 0.2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.nowData {
  margin: 0 auto;
  width: 1869px;
  overflow: hidden;
  margin-bottom: 15px;
}

.online-time {
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  text-align: right;
}

.online-time img {
  vertical-align: middle;
  margin-right: 10px;
}

.online-time span {
  display: inline-block;
  vertical-align: middle;
}

.main_body_inner {
  margin: 0 auto;
  width: 100%;
  /* height: 640px; */
  color: #fff;
  overflow-y: auto;
  margin-top: 30px;
}

.inner-header {
  width: 100%;
  height: 90px;
  color: #fff;
  background: url(./assets/header.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 38px;
  text-align: center;
  line-height: 100px;
  margin: 0 auto;
  margin-top: -44.5px;
}

.header-box {
  display: flex;
  justify-content: space-between;
}

.inline_block {
  display: inline-block;
}

.vertical_top {
  vertical-align: top;
}

.footer-box {
  display: flex;
  padding: 15px 0;
}

.footer-box>.left {
  width: 1214px;
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(23, 42, 136, 0.2);
  overflow: hidden;
  margin-right: 15px;
}

.footer-box>.right {
  background-color: rgba(23, 42, 136, 0.2);
  width: 570px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001336;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #001336;
}

::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  border-radius: 12px;
}

::-webkit-scrollbar {
  height: 4px !important;
  /*滚动条宽度*/
}

.statistics-box {
  padding: 10px;
  background: linear-gradient(180deg, #144173 0%, #072046 100%);
  text-align: center;
  margin-right: 20px;
  padding-top: 5px;
}

.statistics-box p {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0;
}

.statistics-box p:nth-of-type(1) {
  color: #8bd5ff;
}

.middle_inner {
  display: flex;
  justify-content: space-between;
}
</style>
